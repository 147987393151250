{
  "accept": "Přijmout",
  "acceptedcalls": "Přijaté hovory",
  "active": "Aktivní",
  "add": "Přidat",
  "add_notes": "Přiložit poznámku",
  "add-contact-number": "Přidat kontakt, číslo",
  "add-user": "Přidat uživatele",
  "addparticipant": "Přidat účastníka",
  "addresslabel": {
    "work": "Práce"
  },
  "admin-portal": "Portál Admin",
  "admin-user-form": {
    "select-user": "Vyberte prosím uživatele",
    "successfully-created": "Uživatel byl úspěšně vytvořen.",
    "successfully-updated": "Uživatel byl úspěšně aktualizován.",
    "user-doesnt-exist": "Tento uživatel neexistuje",
    "validation-error": "Ve vašem formuláři byly chyby. Opravte je prosím a zkuste to znovu."
  },
  "agent": "Agenti",
  "agent-status": {
    "all": "Vše",
    "available": "Dostupný",
    "break": "Pauza",
    "in-call": "V Rozhovoru",
    "inactive": "Neaktivní",
    "logged-in": "Přihlášen",
    "logged-out": "Odhlášeno",
    "wrap-up": "Následné Zpracování"
  },
  "agents": "Agenti",
  "agentspace": "Panel agentů",
  "all": "Zobrazit vše",
  "all_contacts": "Všechny kontakty",
  "all-read": "Vše přečteno",
  "allContacts": "Všechny kontakty",
  "answered": "Zodpovězeno",
  "AssistantTelephoneNumber": "Asistent",
  "at-busy": "Když je obsazeno",
  "audio": {
    "audio": "Audio",
    "canteditoutputs": "V tomto prohlížeči bohužel nelze upravovat nastavení zvukového výstupu.",
    "defaultinput": "Výchozí",
    "defaultoutput": "Výchozí (probíhající volání se neresetují)",
    "externalRingtone": "Vyzváněcí tón externího hovoru",
    "inputs": "Vstup",
    "internalRingtone": "Interní vyzváněcí tón",
    "outputs": "Výstup",
    "ringingoutput": "Výstup vyzvánění",
    "ringonboth": "Vyzvánět na obou zařízeních"
  },
  "autologinfailed": "Automatické přihlášení se nezdařilo",
  "available": "Dostupný",
  "averagecallduration": "Průměrná doba hovoru",
  "back": "Vrátit se",
  "backdrop_inactive_set_break": "Protože jste přestali přijímat hovory, váš stav byl nastaven na Pauzu. Nejste aktivní a již nedostáváte žádné hovory z fronty. Důvod pauzy je: Z důvodu nečinnosti nastavena Pauza.",
  "backdrop_return_to_active": "Chcete-li být znovu aktivní, musíte změnit svůj stav na Přihlášen.",
  "backdrop_supervisor_set_break": "Váš supervizor změnil váš stav na Pauza. Nejste aktivní a již nedostáváte žádné hovory z fronty. Důvod pauzy je: Pozastaveno nadřízeným.",
  "backdrop_you_are_set_to_break": "Byli jste uvedeni do stavu Pauza.",
  "break": "Pauza",
  "break_message": "Máte přestávku",
  "break-comment": {
    "BACKOFFICE": "Backoffice",
    "BREAK": "Pauza",
    "break-comment": "Důvod přerušení",
    "MEETING": "Rozhovor",
    "select-prompt": "Vyberte důvod přerušení",
    "SUPERVISOR": "Supervizor"
  },
  "break-reason": "Důvod pauzy",
  "Business2TelephoneNumber": "Podnikání 2",
  "BusinessFaxNumber": "Faxový obchod",
  "BusinessTelephoneNumber": "Podnikání",
  "busy": "Obsazeno",
  "call_history": "Historie volání",
  "call-center": {
    "agent-activity": {
      "available": "Dostupné Na",
      "break": "Pauza",
      "in-call": "Ve Výzvě",
      "in-wrapup": "V Dokončení",
      "log-in": "Přihlášení",
      "logged-in": "Přihlášen",
      "logged-out": "Odhlášení",
      "wrapup": "Dokončení"
    },
    "agents-in-queue": "Zástupci v této frontě",
    "call-analytics": {
      "abandoned": "Zrušený",
      "handled": "Přijatý",
      "in-call": "Ve výzvě",
      "waiting": "Čekající"
    },
    "direct-call": "Přímé volání",
    "no-logged-in-agents": "V této frontě nejsou v současné době přihlášeni žádní agenti.",
    "queue": "Fronta",
    "tier": "Úroveň",
    "todays-calls": "Dnešní hovory"
  },
  "call-center-queues": {
    "abandonedResumeAllowed": "Obnovení opuštěného volajícího",
    "abandonedResumeAllowedToggleDescription": "Umožnit volajícímu obnovit jeho pozici ve frontě.",
    "addAgent": "Vybraný agent",
    "addDescription": "Přidat popis",
    "addName": "Přidat jméno",
    "addQueue": "Přidat frontu",
    "addQueueError": "Při přidávání fronty nastal problém",
    "addQueueSuccess": "Fronta byla úspěšně přidána.",
    "addSupervisor": "Zvolte vedoucího pracovníka",
    "agent-with-fewest-calls": "Agent s nejmenším počtem hovorů",
    "agent-with-least-talk-time": "Agent s nejkratší dobou hovoru",
    "agents": "Agenti",
    "agentsSectionDescription": "Úrovně přiřazují agenty do front.",
    "announceFrequency": "Frekvence oznamování",
    "announceFrequencyFieldDescription": "Po kolika sekundách se má čekající zpráva opakovaně přehrát.",
    "announceSound": "Zvukové upozornění",
    "announceSoundFieldDescription": "Čekající zpráva, která se volajícímu přehraje v opakujících se intervalech.",
    "availableLessThan": "K dispozici méně než (v %)",
    "call-center-queues": "Fronty v call centru",
    "callerOutboundName": "Odchozí název",
    "callerOutboundNameDescription": "Zadejte jméno odchozího externího volajícího.",
    "callerOutboundNumber": "Odchozí číslo",
    "callerOutboundNumberDescription": "Zadejte odchozí číslo externího volajícího.",
    "callerPrefix": "Předvolba volajícího",
    "callerPrefixFieldDescription": "Zadejte předponu, která se má zobrazit před telefonním číslem příchozím volajícím.",
    "cancel": "Zahodit změny",
    "deleteQueue": "Odstranění fronty",
    "deleteQueueConfirmationMessage": "Opravdu chcete smazat frontu \"{name}\"?",
    "deleteQueueConfirmationTitle": "Fronty call centra",
    "description": "Popis",
    "discardCall": "Opustit volajícího",
    "discardCallFieldDescription": "Zadejte, po kolika sekundách má být volání zahozeno.",
    "editQueue": "Upravit frontu",
    "editQueueError": "Problém nastal při úpravě fronty",
    "editQueueSuccess": "Fronta byla úspěšně upravena.",
    "extension": "Číslo",
    "greeting": "Ohlášení",
    "inBreakMoreThan": "O přestávce více než (v %)",
    "incomingCalls": "Volající (fronta)",
    "level": "Úroveň",
    "logged-queues": "Přiřazené fronty",
    "loggedLessThan": "Přihlášeno méně než (v %)",
    "longest-idle-agent": "Agent s nejdelším volným časem",
    "maxWaitingTime": "Maximální čekací doba",
    "maxWaitingTimeFieldDescription": "Doba čekání v sekundách před provedením akce.",
    "maxWaitingTimeNoAgent": "Maximální čekací doba bez agenta",
    "maxWaitingTimeNoAgentFieldDescription": "Doba, kterou volající stráví ve frontě před plánovaným provedením vybrané akce.",
    "maxWaitingTimeNoAvailableAgent": "Maximální doba čekání bez dostupného agenta",
    "maxWaitingTimeNoAvailableAgentFieldDescription": "Doba, kterou volající stráví ve frontě před plánovaným provedením vybrané akce.",
    "musicOnHold": "Hudba na počkání",
    "name": "Jméno",
    "numberOfWaiting": "Počet čekajících volajících",
    "orangeStart": "Oranžová od",
    "order": "Objednávka",
    "pos": "Pozice",
    "queues": "Fronty",
    "random": "Náhodně",
    "recording": "Nahrávka",
    "recordingToggleDescription": "Uložte nahrávku.",
    "redStart": "Červená od",
    "removeQueueError": "Problém nastal při mazání fronty",
    "removeQueueSuccess": "Fronta byla úspěšně odstraněna.",
    "ring-all": "Všechny zvoní",
    "round-robin": "Točení v kruzích",
    "save": "Uložit",
    "search": "Vyhledávání front v call centru",
    "selectAction": "Vybrat Čas odložení akce",
    "selectExtension": "Zvolte číslo",
    "selectGreeting": "Zvolte ohlášení",
    "selectMusicOnHold": "Výběr podržené hudby",
    "selectOrder": "Zvolte objednávku",
    "selectSound": "Výběr zvuku",
    "selectStrategy": "Zvolte strategii",
    "selectTime": "Nastavený čas",
    "selectWaitingTime": "Nastavení maximální čekací doby",
    "sequentially-by-agent-order": "Postupně podle pořadí agentů",
    "sequentially-by-next-agent-order": "Postupně podle pořadí dalšího agenta",
    "setPrefix": "Nastavení předpony",
    "strategy": "Strategie",
    "supervisor": "Vedoucí pracovník",
    "supervisorSectionDescription": "Zvolte jednoho nebo více správců pro tuto frontu.",
    "thresholds": "Prahová hodnota",
    "thresholdsSectionDescription": "Nastavení prahových hodnot pro vizuální upozornění.",
    "tierLevel": "Úroveň úrovně",
    "tierPosition": "Pozice na úrovni",
    "tierRuleNoAgentNoWait": "Žádný agent, žádná čekací doba",
    "tierRuleNoAgentNoWaitToggleDescription": "Pokud není k dispozici žádný agent, volající přejde přímo na další úroveň.",
    "tierRules": "Pravidla pro jednotlivé úrovně",
    "tierRulesToggleDescription": "Určete, zda se na frontu mají vztahovat pravidla úrovně.",
    "tierRuleWaitMultiply": "Prodloužení čekací doby pro pravidla pro jednotlivé úrovně",
    "tierRuleWaitMultiplyToggleDescription": "Prodloužení čekací doby, pokud volající čeká do další úrovně.",
    "timeBaseValue": "Základní časové skóre",
    "timeBaseValueFieldDescription": "Volba, zda se doba, kterou volající strávil v systému, započítává do čekací doby.",
    "timeout": "Čas odložení",
    "timeoutAction": "Čas odložení akce",
    "timeoutActionFieldDescription": "Nastavení akce, která se má provést po dosažení maximální čekací doby.",
    "top-down": "Shora dolů",
    "value": "Hodnota"
  },
  "call-detail": "Podrobnosti o volání",
  "call-forward-settings": "Předání volání",
  "call-forwarding": {
    "at-busy": "Pokud je aktivováno, aktivní poštovní schránka telefonní pobočky bude přepsána.",
    "no-answer": "Pokud je aktivováno, aktivní poštovní schránka bude přepsána.",
    "not-registered": "Přesměrování volání na nové místo určení, pokud je původní místo určení před aktivací schránky nedosažitelné.",
    "phone-number": "Přesměrování všech hovorů na toto číslo."
  },
  "call-group": {
    "active": "Aktivní",
    "confirm": "Potvrdit",
    "deleteGroupConfirmationMessage": "Opravdu chcete smazat skupinu \"{name}\"?",
    "deleteGroupConfirmationTitle": "Skupiny volání",
    "enter-groups": "Zde zadejte skupiny vyzvednutí uživatele.",
    "groups": "Skupiny",
    "no-confirmation": "Bez potvrzení",
    "not-assigned": "Aktuálně nejste přiřazen do žádné skupiny hovorů"
  },
  "call-group-settings": "Svozové skupiny",
  "call-history": "Historie volajících",
  "call-reason": "Důvod volání",
  "call-reasons": {
    "banking-information": "Bankovní spojení",
    "customer-data-changed": "Změna údajů o zákaznících",
    "no-reason": "Žádný důvod",
    "opening-time-inquiry": "Dotaz na otevírací dobu",
    "order": "Objednávka",
    "product-inquiry": "Dotaz na produkt",
    "support": "Podpora"
  },
  "call-settings": {
    "AnnouncementManagement": "Správa oznámení",
    "Blacklisting": "Černá listina",
    "CallBroadcast": "Vysílání hovoru",
    "IVR": "IVR",
    "label": "Nastavení telefonie",
    "OpenTimes": "Svátky/otevírací doba",
    "ParkingFunction": "Funkce parkování",
    "Pickup": "Zvednout",
    "RingGroups": "Souhrnné skupiny volání",
    "SwitchBoardSettings": "Ústředna"
  },
  "CallbackTelephoneNumber": "Zpětná zpráva",
  "callcenter-settings": {
    "label": "Nastavení call centra",
    "QueuesSettings": "Fronty call centra",
    "Supervisor": "Vedoucí call centra"
  },
  "callduration": "{seconds} Telefonováno s",
  "caller": "Volající",
  "callforward": {
    "all": "Přesměrování všech volání",
    "alldescription": "Přesměrování všech příchozích hovorů na cíl.",
    "busy": "Když je obsazeno",
    "busydescription": "Pokud je aktivní, bude aktivovaná poštovní schránka telefonní pobočky přepsána.",
    "callforward": "Přesměrování volání",
    "noanswer": "Žádná odpověď",
    "noanswerdescription": "Pokud je aktivní, bude aktivovaná poštovní schránka telefonní pobočky přepsána.",
    "notregistered": "Neregistrováno",
    "notregistereddescription": "Přesměrování volání na nové místo určení, pokud je původní místo určení před aktivací schránky nedosažitelné.",
    "target": "Cíl"
  },
  "calls": "Volání",
  "callscript": {
    "callscript": "Skript volání",
    "removecallscript": "Odstranit",
    "selectcallscript": "Vyberte skript volání",
    "testcallscript": "Testovat"
  },
  "calltime": "Čas volání",
  "cancel": "Přerušit",
  "canceled": "Přerušeno",
  "CarTelephoneNumber": "Auto",
  "changelicense": {
    "cancel": "Zrušení",
    "content": "Upozorňujeme, že změna typu licence může mít obchodní důsledky a vést ke změně nákladů. Opravdu chcete změnit uživatelskou licenci na licenci \"{license}\"?",
    "save": "Ano, změna",
    "title": "Změna licence"
  },
  "changelog": "Protokol změn",
  "checkwtgcloud": "Myslíte wtg.cloud místo wtg.com?",
  "choose-agent-status": "Zvolte status agenta.",
  "choose-break-reason": "Zvolte prosím důvod přestávky.",
  "clear-selection": "Vyprázdnit výběr",
  "close": "Zavřít",
  "closewindowdespitecall": "Stále probíhá hovor, přesto chcete zavřít?",
  "cold": "Studený",
  "coldtransfer": "Předat",
  "company": "Společnost",
  "conference-member": "Účastník konference",
  "connect_to": "Sdělit",
  "contact": "Kontakt",
  "contact-details": {
    "add": "Přidání nového kontaktu",
    "address": "Adresa",
    "contact-delete": "Chcete tento kontakt odstranit?",
    "contact-delete-title": "Odstranění kontaktu",
    "country": "Země",
    "edit": "Upravit kontaktní údaje",
    "email": "E-mail",
    "location": "Místo",
    "position": "Pozice",
    "postcode": "PSČ",
    "title": "Kontaktní údaje"
  },
  "contact-form": {
    "delete-error": "Kontakt nelze smazat",
    "delete-success": "Kontakt úspěšně odstraněn",
    "enterAddress": "Zadejte ulici a číslo",
    "enterCompany": "Zadejte název společnosti",
    "enterCountry": "Zadejte název země",
    "enterEmail": "Zadejte e-mailovou adresu",
    "enterFirstname": "Zadejte křestní jméno",
    "enterLastname": "Zadejte příjmení",
    "enterLocation": "Zadejte název lokality",
    "enterMobile": "Zadejte číslo mobilního telefonu",
    "enterPosition": "Zadejte pozici",
    "enterPostcode": "Zadejte poštovní směrovací číslo",
    "enterWork": "Zadejte pracovní telefonní číslo",
    "put-error": "Kontakt nebylo možné aktualizovat",
    "put-success": "Kontakt byl úspěšně aktualizován",
    "save-error": "Kontakt se nepodařilo uložit",
    "save-success": "Kontakt byl úspěšně uložen"
  },
  "contact-wtg": "Obraťte se na WTG.",
  "contactinfo": "Kontaktní údaje",
  "contacts": {
    "contacts": "Kontakty",
    "currentlyImported": "Počet importovaných kontaktů",
    "importOutlookContacts": "Importovat kontakty aplikace Outlook",
    "outlook": "Kontakty aplikace Outlook",
    "outlookExplanation": "Importovat lze kontakty, které jsou udržovány prostřednictvím aplikace Outlook a nacházejí se v systému. Importované kontakty zůstanou po importu v systému a nejsou dostupné v jiných koncových zařízeních.",
    "refreshOutlookContacts": "Aktualizovat kontakty aplikace Outlook",
    "removeOutlookContacts": "Odebrat kontakty aplikace Outlook",
    "status": {
      "failure": "Při importu kontaktů došlo k chybě.",
      "running": "Import kontaktů …",
      "success": "Kontakty byly úspěšně importovány."
    }
  },
  "currentversion": "Současná verze",
  "date": "Datum",
  "declined": "Odmítnuto",
  "delete": "Smazat",
  "devices-settings": {
    "label": "Terminály",
    "Provisoning": "Zajišťování",
    "Registration": "Registrace"
  },
  "dialing": "Volit",
  "dialpad": "Vytáčení",
  "direct": "Přímo",
  "diverted-by": "Předáno",
  "done": "Hotovo",
  "duration": "Doba trvání",
  "edit": "Upravit",
  "email-address": "E-mailová adresa",
  "enabled": "Aktivní",
  "endcall": "Odejít",
  "enter-new-message": "Zadejte novou zprávu",
  "enternameornumber": "Zadejte číslo",
  "error": {
    "call-history-loading-error": "Při načítání historie volání došlo k chybě.",
    "tokeninvalid": "Platnost vašeho obnovení hesla bohužel vypršela. Požádejte o nové prostřednictvím funkce Zapomenuté heslo.",
    "TOO_MANY_CONTACTS": "Příliš mnoho přiřazených kontaktů",
    "TOO_MANY_DESTINATIONS": "Příliš mnoho příchozích telefonních čísel",
    "TOO_MANY_EXTENSIONS": "Příliš mnoho přiřazených telefonních poboček",
    "TOO_MANY_VOICEMAILS": "Příliš mnoho přiřazených hlasových zpráv",
    "unknown": "Bohužel to nefungovalo, zkuste to znovu.",
    "wronglink": "Tento odkaz na obnovení hesla je nefunkční. Zavřete tuto stránku a znovu klikněte na odkaz ve svém e-mailu."
  },
  "error-domain": "Zkontrolujte doménu (část za znakem „{'@'}“).",
  "error400": "Zkontrolujte své uživatelské jméno a zkuste to znovu.",
  "error403": "Možná jste požádali o příliš mnoho obnovení hesla najednou.",
  "errorKey": "Chyba",
  "everyone-happy": "Všichni jsou šťastní.",
  "extension": "Telefonní pobočka",
  "extensionnumber": "Číslo telefonní pobočky",
  "extensionsettings": {
    "active": "Aktivní",
    "allowcallwaiting": "Povolit signalizaci čekání volání",
    "busyonbusy": "Busy-On-Busy",
    "busyonbusydecision": "Více příchozích volání",
    "callGroups": "Skupiny volání",
    "exceptions": "Výjimky",
    "extensionsettings": "Nastavení linky",
    "secretary": "Sekretářka",
    "secretary-settings": "Nastavení sekretářky"
  },
  "external": "Externí",
  "external-inbound-phone-number": "Příchozí",
  "external-outbound-phone-number": "Odchozí",
  "externalextensionnumber": "Moje číslo",
  "externalnumbers": "Externí telefonní čísla",
  "failed": "Nedosažitelný",
  "favoriteAdd": "Přidat k oblíbeným",
  "favoriteRemove": "Odebrat z oblíbených",
  "favorites": "Oblíbené",
  "firstname": "Jméno",
  "followme": {
    "adddestination": "Přidat",
    "delay": "Zpoždění",
    "delete": "Smazat",
    "deleteTargetConfirmationMessage": "Opravdu chcete smazat cíl?",
    "deleteTargetConfirmationTitle": "Následuj mě",
    "followme": "Přesměrování hovorů",
    "ignorebusy": "Ignorovat obsazeno",
    "ignorebusydescription": "Přerušit volání, když je místo určení obsazené.",
    "prompt": "Dotazy",
    "promptoff": "Vyp",
    "prompton": "Zap",
    "target": "Cíl",
    "timeout": "Čas odložení"
  },
  "forgotPassword": "Zapomenuté heslo?",
  "forward_to": "Předat",
  "fromconference": "z konference s {0} účastníky",
  "fromconferenceOptions": "z konference bez účastníků | z konference s jedním účastníkem | z konference s {n} účastníky",
  "globalContact": "Centrálně spravovaný kontakt",
  "globalContacts": "Centrální kontakty",
  "group": "Skupina",
  "groupname": "Název skupiny",
  "hang-up": "Zavěsit",
  "help": "Stránka nápovědy",
  "hide": "Skrýt",
  "history": "Průběh",
  "historytabs": {
    "all": "Vše",
    "inbound": "Příchozí",
    "missed": "Zmeškané",
    "outbound": "Odchozí"
  },
  "hold": "Držet",
  "holding": "Držet",
  "home": "Domov",
  "Home2TelephoneNumber": "Soukromé 2",
  "HomeFaxNumber": "Soukromý fax",
  "HomeTelephoneNumber": "Soukromé",
  "homeview": {
    "no-missed-calls": "Nejsou žádné nové zmeškané hovory.",
    "up-to-date": "Jste aktuální."
  },
  "hotkey": {
    "directcall": "Zavolat přímo",
    "enableHotkey": "Povolit klávesovou zkratku",
    "hotkey": "Klávesové zkratky",
    "keybidingPermissions": "Povolit oprávnění klávesových zkratek",
    "movetoforeground": "Přenést do popředí"
  },
  "hourabbrev": "hod",
  "hung-up": "Zavěsil",
  "importedFrom": "Importováno z",
  "in-call": "V rozhovoru",
  "inactive": "Neaktivní",
  "inactiveUser": "Chyba: Uživatel není aktivní.",
  "inbound": "Příchozí",
  "inbound-calls": "Příchozí volání",
  "incomingcall": "Příchozí volání",
  "incomingcallfrom": "Příchozí volání od {0}",
  "incomingcalls": "Žádná příchozí volání | Příchozí volání | {n} Počet příchozích volání",
  "information": {
    "clientversion": "Klientská verze",
    "information": "Informace o klientovi",
    "showchangelog": "Zobrazit celý protokol změn"
  },
  "insertnameornumber": "Zadejte jméno nebo číslo",
  "integration-settings": {
    "azure-client-id": "ID aplikace (klienta)",
    "azure-client-secret": "Hodnota tajného klíče klienta",
    "azure-tenant-id": "ID adresáře (nájemce)",
    "AzureSettings": "Integrace Azure",
    "configuration": "Konfigurace Azure",
    "delete-config-msg": "Opravdu chcete smazat konfiguraci Azure?",
    "description": "Zadáním údajů automaticky aktivujete integraci Azure, která zahrnuje synchronizaci přítomnosti v MS Teams a synchronizaci kontaktů.",
    "label": "Integrace",
    "title": "Nastavení integrace Azure"
  },
  "internal": "Interní",
  "internalextensionnumber": "Provolba",
  "invalid-data": "Poskytnuty neplatné údaje. Prosím zkontrolujte a zkuste znovu",
  "invalid-uuid": "Hodnota by měla být ve formátu UUID",
  "invalidphonenumber": "Neplatné volané číslo.",
  "ISDNNumber": "ISDN",
  "ivr": {
    "active": "Aktivní",
    "addDescription": "Přidat popis",
    "addExtension": "Přidat rozšíření",
    "addIVR": "Přidat IVR",
    "addName": "Přidat název",
    "callerPrefix": "Předpona volajícího",
    "callerPrefixFieldDescription": "Nastaví předponu na jméno volajícího.",
    "cancel": "Zrušit",
    "defineCallOptions": "Definovat volby volajícího pro IVR menu.",
    "delayTimeBetweenInput": "Časový limit mezi vstupy",
    "delayTimeBetweenInputFieldDescription": "Počet milisekund čekání mezi zadáváním čísel.",
    "deleteIVR": "Smazat IVR",
    "deleteIvrConfirmationMessage": "Opravdu chcete smazat IVR \"{name}\"?",
    "deleteIvrConfirmationTitle": "Nabídka IVR",
    "description": "Popis",
    "digitLength": "Délka čísel",
    "digitLengthFieldDescription": "Maximální počet povolených číslic.",
    "editIVR": "Upravit IVR",
    "enterTime": "Nastavit časový limit",
    "enterTimeFieldDescription": "Počet milisekund čekání po přehrání pozdravu nebo potvrzovací makra.",
    "exitAction": "Akce při ukončení",
    "extension": "Rozšíření",
    "greeting": "Pozdrav",
    "invalidOptionSound": "Zvuk neplatné volby",
    "invalidOptionSoundFieldDescription": "Přehrává se, když je vybrána neplatná možnost.",
    "ivr": "IVR",
    "IVRs": "IVR Menu",
    "language": "Jazyk",
    "long": "Dlouhý",
    "longGreetingDescription": "Dlouhý pozdrav je přehrán při vstupu do menu.",
    "maxFailures": "Max. Počet neúspěchů",
    "maxFailuresFieldDescription": "Maximální počet pokusů před opuštěním.",
    "maximumTimeouts": "Max. časové limity",
    "maximumTimeoutsFieldDescription": "Maximální počet časových limitů před opuštěním.",
    "name": "Název",
    "option": "Možnost",
    "options": "Možnosti",
    "order": "Pořadí",
    "parentMenu": "Nadřazené menu",
    "pin": "PIN",
    "pinFieldDescription": "Volitelné číslo PIN k zabezpečení přístupu do menu.",
    "recordingAnnouncement": "Oznámení musí být přidána prostřednictvím hlavního správce WTG. Pokud chcete přidat nové zvukové soubory, pošlete je na adresu: pure.tsc{'@'}wtg.com",
    "ringback": "Zvuk při odepisování",
    "ringbackFieldDescription": "Definuje, co volající uslyší, když je voláno cílové číslo.",
    "save": "Uložit",
    "search": "Vyhledávání IVR",
    "selectAction": "Vybrat akci",
    "selectAnnouncement": "Vybrat pozdrav",
    "selectExitActionFieldDescription": "Vybrat akci, která se má provést, pokud IVR opustí.",
    "selectLanguage": "Vybrat jazyk",
    "selectParentMenu": "Vybrat nadřazené menu",
    "selectPrefix": "Vybrat předponu",
    "selectSound": "Vybrat zvuk",
    "selectTarget": "Vybrat cíl",
    "setDelayTimeBetweenInput": "Nastavit časový limit mezi vstupy",
    "setDigitLength": "Nastavit délku čísel",
    "setMaxFailures": "Nastavit max. počet neúspěchů",
    "setMaximumTimeouts": "Nastavit max. časové limity",
    "setPin": "Nastavit PIN",
    "short": "Krátký",
    "shortGreetingDescription": "Krátký pozdrav je přehrán při návratu do menu.",
    "soundOnMenuExit": "Zvuk při opuštění menu",
    "soundOnMenuExitFieldDescription": "Přehrává se při opuštění menu.",
    "target": "Cíl",
    "time": "Čas",
    "timeout": "Časový limit"
  },
  "keypad": "Číselné pole",
  "label-active": "Přihlášeno",
  "label-idle": "Pauza",
  "language": "Jazyk",
  "languages": "Jazyky",
  "lastname": "Příjmení",
  "licence": "Licence",
  "loading": "Načítání …",
  "logged-out": "Odhlášeno",
  "logging_in_message": "Budete přihlášeni",
  "logging_out_message": "Budete odhlášeni",
  "logIn": "Přihlásit",
  "logout": "Odhlásit",
  "logs": {
    "delete-logs": "Vymazat protokoly",
    "deleted": "Protokoly byly vymazány.",
    "disclaimer": "Protokoly jsou uloženy pouze lokálně a jsou odstraněny při zavření klienta nebo prohlížeče.\nProstřednictvím „odeslat protokoly“ jsou zpřístupněny WTG.",
    "failure": "Chyba při odesílání protokolů.",
    "logs": "Protokoly",
    "note": "Přidat poznámku do protokolů",
    "oldest-log": "Nejstarší protokol",
    "running": "Odesílání protokolů …",
    "send-logs": "Poslat protokoly",
    "success": "Protokoly byly odeslány."
  },
  "medialibrary-settings": {
    "accessible-for": "Použitelné pro",
    "add-audio-title": "Přidání nového zvukového souboru",
    "audio-category-placeholder": "Vyberte kategorii",
    "audio-language-placeholder": "Výběr jazyka",
    "audio-name": "Název",
    "audio-name-placeholder": "Přidání názvu zvuku",
    "audio-usable-for-placeholder": "Zvolte použití",
    "audio-users-placeholder": "Vyberte uživatele",
    "AudioLibrarySettings": "Audio",
    "browse-files": "Procházení souborů",
    "cancel": "Zrušit",
    "categories": {
      "GREETING": "Uvítání",
      "IVR": "IVR hlášení",
      "MUSIC_ON_HOLD": "Hudba při čekání",
      "OTHER": "Jiné",
      "RINGTONE": "Vyzváněcí tón"
    },
    "category": "Kategorie",
    "columns": {
      "category": "Kategorie",
      "language": "Jazyk",
      "name": "Název",
      "type": "Typ",
      "upload-time": "Čas nahrání",
      "uploaded-by": "Uploadnul",
      "usable-for": "Použitelné pro",
      "used-in": "Používá se v"
    },
    "confirm-delete": "Ano, vymazat",
    "delete-audio-title": "Odstranění zvukového souboru",
    "delete-confirmation-msg": "Opravdu chcete odstranit zvukový soubor \"{name}\"?",
    "delete-greeting-dependencies-msg": "Tento zvuk je právě používán, určitě jej chcete odstranit? Pokud ano, soubor se odstraní a nahradí se výchozím zvukovým souborem.",
    "delete-ivr-dependencies-msg": "Tento zvuk je v současné době používán, nejprve je třeba odstranit závislosti.",
    "drag-drop": "Přetáhněte sem soubory",
    "edit-audio-title": "Úprava zvukového souboru",
    "edit-success": "Zvukový soubor byl úspěšně aktualizován",
    "file": "Soubor",
    "header": "Zvukové soubory",
    "label": "Zvuková knihovna",
    "language": "Jazyk",
    "legal-disclaimer": "Nahráváním, používáním nebo zobrazováním hudby, audiovizuálního obsahu, zvukových nahrávek, fotografií, uměleckých děl a dalších forem médií chráněných autorským právem na platformě Cloud PURE zaručujete společnosti WTG, že máte povolení a/nebo licenci k použití takového obsahu. Pokud tak učiníte bez povolení nebo licence od vlastníka, porušujete tím zákon o autorských právech a může dojít k odstranění takového obsahu.",
    "or-choose": "nebo",
    "save-success": "Zvukový soubor byl úspěšně vytvořen",
    "search": "Vyhledávání zvukových souborů",
    "title": "Zvuková knihovna",
    "usage-type": {
      "ivr": "IVR nabídka",
      "queue": "Fronta call centra",
      "ring_group": "Skupina Ring Group"
    },
    "used-in-title": "Používá se v",
    "user": "Uživatel",
    "visibility": {
      "personal": "Osobní",
      "public": "Veřejné"
    }
  },
  "members": "Příchozí hovory (fronta)",
  "merge": "Uzavřít zprostředkování",
  "min": "min.",
  "minuteabbrev": "min",
  "missedcall": "Zmeškané",
  "missedcalls": "Zmeškaná volání",
  "MobileTelephoneNumber": "Mobilní telefon",
  "mobiletitel": "Telefon",
  "more": "Více",
  "mute": "Ztlumit",
  "my_favorites": "Moji oblíbení",
  "myActiveQueues": "Moje aktivní fronty",
  "name": "Jméno",
  "newpassword": "Nové heslo",
  "newuser": "Nový uživatel",
  "no": "Ne",
  "no-data": "Žádná data nejsou k dispozici",
  "no_agent": "Nemáte žádného přiděleného agenta",
  "no-answer": "Žádná odpověď",
  "no-number-available": "Není k dispozici žádné číslo",
  "no-one-waiting": "Nikdo nečeká.",
  "noActiveQueues": "Žádné aktivní fronty",
  "nomissedcalls": "O nic jste nepřišli.",
  "not-available": "Není k dispozici žádné číslo",
  "not-registered": "Neregistrováno",
  "not-working": "Bohužel to nefungovalo.",
  "notes": "Poznámky",
  "notice": "Poznámky",
  "notifications": {
    "banner": "Pruh s textem",
    "banner_info": "Při příchodu volání do skupiny zvednutí zobrazit oznamovací pruh s textem",
    "notifications": "Sdělení",
    "pickup": "Zvednout",
    "sound_status": "Zvuk",
    "sound_status_info": "Při příchodu volání do skupiny zvednutí přehrát zvukový signál"
  },
  "notLoggedOut": "Všichni přihlášení",
  "number": "Číslo",
  "numpad": "Číselník",
  "offline_message": "Jste odhlášeni",
  "onbreak": "V pauze",
  "organization": "Podnik",
  "OtherFaxNumber": "Další fax",
  "OtherTelephoneNumber": "Další",
  "outlookContact": "Importováno z aplikace Outlook",
  "Overview": "Přehled",
  "park": "Parkovat",
  "park_call": "Zaparkovat volání",
  "park-call": "Zaparkovat volání",
  "parked-calls": "Zaparkovaná volání",
  "parking": "Parkování",
  "participant": "Účastník",
  "participants": "Účastníci",
  "password": "Heslo",
  "passwordhastocontain8chars": "Heslo musí obsahovat alespoň 8 znaků.",
  "passwordreset-success-msg": "Na uloženou adresu byl zaslán e-mail k obnovení hesla. Zkontrolujte svou poštovní schránku.",
  "passwordsdontmatch": "Hesla se neshodují.",
  "personalContact": "Osobní kontakt",
  "personalContacts": "Osobní kontakty",
  "phone": "Telefon",
  "phone-number": "Přesměrování volání",
  "phonebook-form": {
    "add": "Přidat skupinu kontaktů",
    "delete-content": "Chcete tuto skupinu kontaktů smazat?",
    "delete-error": "Skupinu kontaktů se nepodařilo odstranit",
    "delete-success": "Skupina kontaktů byla úspěšně odstraněna",
    "delete-title": "Smazání skupiny kontaktů",
    "edit": "Upravit skupinu kontaktů",
    "name": "Název skupiny",
    "put-error": "Skupinu kontaktů se nepodařilo aktualizovat",
    "put-success": "Skupina kontaktů byla úspěšně aktualizována",
    "save-error": "Skupinu kontaktů se nepodařilo uložit",
    "save-success": "Skupina kontaktů byla úspěšně uložena",
    "search-contact": "Vyhledat kontakt"
  },
  "phonebook-groups": {
    "contacts-amount": "Počet kontaktů",
    "global-empty": "Žádné centrální skupiny nebyly přidány!",
    "global-title": "Centrální skupiny",
    "personal-empty": "Žádné osobní skupiny nebyly přidány! Klikněte na Přidat pro vytvoření.",
    "personal-title": "Osobní skupiny"
  },
  "phonebook-settings": {
    "CentralContacts": "Centrální kontakty",
    "CentralGroups": "Centrální skupiny",
    "label": "Telefonní seznam"
  },
  "phonelabel": {
    "fax": "Fax",
    "mobile": "Mobilní",
    "other": "Jiné",
    "work": "Práce"
  },
  "pickup": "Zvednout",
  "pickupGroups": {
    "addPickupGroup": "Přidání skupiny pro vyzvednutí",
    "deleteGroupConfirmationMessage": "Opravdu chcete smazat skupinu vyzvednutí \"{name}\"?",
    "deleteGroupConfirmationTitle": "Vyzvednutí skupiny",
    "editPickupGroup": "Upravit skupinu vyzvednutí",
    "members": "Členové",
    "members-number": "Počet členů skupiny",
    "nameTaken": "Název se již používá!",
    "noMembers": "Seznam členů nesmí být prázdný!",
    "notBlank": "Pole nesmí být prázdné!",
    "numberOfMembers": "Počet členů",
    "pickupGroupsHeader": "Skupiny pro vyzvednutí",
    "search": "Vyhledávání skupin pro vyzvednutí"
  },
  "position": "Pozice",
  "post-processing-time": "Doba dodatečného zpracování",
  "previousversion": "Předchozí verze",
  "PrimaryTelephoneNumber": "Hlavní telefon",
  "profile-data": "Profilová data",
  "pureContact": "Kontakt PURE",
  "pureContacts": "Kontakty PURE",
  "queue": "Fronta",
  "queues": "Fronty",
  "queues-waiting": "Čeká",
  "RadioTelephoneNumber": "Rádiové volání",
  "recordings": "Hlasové zprávy",
  "referred-by": "Odesláno",
  "reject": "Odmítnout",
  "repeatpassword": "Zopakovat heslo",
  "repeatuserpassword": "Zopakovat heslo",
  "replayed": "Zodpovězeno",
  "request_supervisor": "Vyžádat si supervizora",
  "request-password-reset-header": "Zapomenuté heslo?",
  "requests_supervisor": "Dotaz na supervizora",
  "reset-password": "Obnovit heslo",
  "resetpassword": "Obnovit heslo",
  "resetsuccessmessage": "Heslo bylo obnoveno.",
  "resetsuccesstitle": "Heslo bylo obnoveno",
  "restriction-errors": {
    "at-least-one-target": "Je třeba přidat alespoň jednu cílovou možnost",
    "duplicate": "Tato hodnota je již přiřazena uživateli {user}",
    "empty": "Nesmí být prázdné",
    "exceeded-quota": "Překročení kvóty pro uživatele",
    "excluded-value-list-errors": {
      "CALL_CENTER_QUEUE": "Toto číslo se již používá pro frontu call centra",
      "DESTINATION": "Toto číslo se již používá pro cíl",
      "EXTENSION": "Toto číslo již používá jiný uživatel",
      "IVR_MENU": "Toto číslo se již používá pro nabídku IVR",
      "other": "Toto číslo se již používá jinde",
      "RING_GROUP": "Toto číslo je již používáno pro skupinu hovorů",
      "TIME_CONDITION": "Toto číslo se již používá pro časovou podmínku"
    },
    "file-too-large": "Soubor je příliš velký. Maximální velikost je {sizeLimit} MB",
    "invalid-file-type": "Nesprávný typ souboru",
    "passwords-not-matching": "Hesla se musí shodovat",
    "regex-errors": {
      "DOMAIN_NUMBER_RESTRICTION": "Toto telefonní číslo není pro váš účet povoleno",
      "EMAIL": "Neplatná e-mailová adresa",
      "EXTENSION_NUMBER": "Může obsahovat pouze číslice a nesmí začínat nulou",
      "other": "Nesprávný formát",
      "PHONE_NUMBER": "Musí to být telefonní číslo a musí začínat znakem +",
      "USERNAME": "Uživatelské jméno se musí skládat z písmen, číslic a znaků „-“, „_“ nebo „.“.",
      "VOICEMAIL_PASSWORD": "Musí mít 6 až 16 číslic"
    },
    "too-short": "Heslo musí obsahovat alespoň {minimum} znaků",
    "unique-extension": "Rozšíření {extension} je již obsazeno.",
    "unique-inbound-number": "Příchozí číslo {inboundNumber} je již obsazeno.",
    "unique-username": "Uživatelské jméno {username} je již obsazeno.",
    "unknown-error": "Chyba ověření",
    "wrong-format": "Nesprávný formát"
  },
  "return-to-login": "Zpět k přihlášení",
  "revert": "Zahodit změny",
  "ring-groups": {
    "accept": "Přijmout",
    "active": "Aktivní",
    "add": "Přidat",
    "addCallGroup": "Přidat skupinu",
    "addDescription": "Přidat popis",
    "addExtension": "Vybrat pobočku",
    "addName": "Přidat název",
    "addTarget": "Přidat cíl",
    "busyTone": "Zaneprázdněný tón",
    "busyToneFieldDescription": "Určete, co volající slyší, když je cíl volán.",
    "callerIdName": "Jméno volajícího",
    "callerIdNameFieldDescription": "Nastavit jméno ID volajícího pro odchozí externí hovory.",
    "callerIdNumber": "Číslo volajícího",
    "callerIdNumberFieldDescription": "Nastavení čísla ID volajícího pro odchozí externí hovory.",
    "callGroupForward": "Přesměrování skupiny lovu",
    "callGroupForwardFieldDescription": "Přesměrování celé lovecké skupiny na vybraný cíl.",
    "callGroupForwarding": "Přeposílání skupin lovu",
    "callGroupForwardingFieldDescription": "Přesměrování volané skupiny hovorů na jiné místo určení.",
    "callGroups": "Skupiny lovu",
    "callTimeout": "Časový limit volání",
    "cancel": "Zahodit změny",
    "chooseCallTimeout": "Vybrat časový limit volání",
    "delay": "Zpozdit",
    "deleteCallGroup": "Odstranění skupiny",
    "description": "Popis",
    "editCallGroup": "Upravit skupinu",
    "emailAddress": "E-mailová adresa",
    "enterprise": "Podnik",
    "extension": "Pobočka",
    "feesForwarding": "Přesměrování poplatků za volání",
    "followMe": "Následujte mě",
    "followMeFieldDescription": "Vyberte možnost \"Následujte mě\" pro cíl skupiny zvonění.",
    "forwarding": "Přesměrování",
    "forwardingFieldDescription": "Vyberte, zda se má v rámci skupiny použít přesměrování hovorů.",
    "greeting": "Pozdrav",
    "groupMembers": "Členové skupiny",
    "groups": "Členové",
    "missedCalls": "Zmeškané hovory",
    "missedCallsFieldDescription": "Vyberte, zda má být někdo upozorněn e-mailem.",
    "name": "Název",
    "random": "Náhodný",
    "recall": "Zpětné volání",
    "recallFieldDescription": "Určuje, co volající slyší, když je cíl volán.",
    "rollover": "Rollover",
    "save": "Uložit",
    "search": "Vyhledávání skupin volání",
    "selectCallerIdName": "ID volajícího Vytočit název",
    "selectCallerIdNumber": "Vybrat číslo volajícího",
    "selectContact": "Vybrat kontakt",
    "selectFeesForwarding": "Vybrat přesměrování poplatků za volání",
    "selectGreeting": "Vybrat pozdrav",
    "selectNumber": "Vyberte číslo",
    "selectSound": "Vybrat tón",
    "selectStrategy": "Vybrat strategii",
    "selectTarget": "Vybrat cíl",
    "sequence": "Sekvence",
    "simultaneous": "Současně",
    "strategy": "Strategie",
    "target": "Cíl",
    "targetByTimeLimit": "Cíl podle časového limitu",
    "targets": "Cíle",
    "targetsSectionDescription": "Přidání cílů a parametrů do skupiny kroužků.",
    "timeLimit": "Časového limitu",
    "typeOfNotification": "Typ oznámení"
  },
  "ringduration": "{seconds} Zvonilo s",
  "save": "Uložit",
  "savepassword": "Uložit heslo",
  "search": "Hledat",
  "search-for-contact": "Vyhledat kontakt",
  "search-for-user": "Hledat uživatele",
  "search-name": "Hledat",
  "sec": "sek.",
  "secondabbrev": "s",
  "secretary-function": {
    "active": "Aktivní",
    "target": "Moje sekretářka"
  },
  "secretary-function-settings": "Nastavení sekretářky",
  "section_header_second": "KONTAKTY",
  "select-break-reason": "Vyberte důvod přerušení",
  "serverunreachable": "Selhalo: Server {0} není dostupný.",
  "service-level": "Úroveň služby",
  "set-agent-status": "Nastavení stavu agenta",
  "set-status": "Nastavit status",
  "settings": "Nastavení",
  "show_all": "Zobrazit vše",
  "signWithMicrosoft": "Přihlášení pomocí účtu Microsoft",
  "softclient-settings": {
    "label": "Nastavení softwarového klienta",
    "MultiField": "Více polí",
    "Shortcuts": "Klávesové zkratky"
  },
  "sound-error": "Problémy s mikrofonem",
  "soundoutputsettings": "Nastavení výstupu",
  "speaker": "Reproduktor",
  "sso": {
    "error": {
      "ACCESS_TOKEN_NOT_AVAILABLE": "Přístupový token není k dispozici.",
      "GENERAL_ERROR": "Během SSO autentizace došlo k neočekávané chybě",
      "MULTIPLE_ACCOUNTS": "S touto e-mailovou adresou bylo nalezeno více uživatelů. Kontaktujte prosím administrátora.",
      "SSO_DISABLED": "Jednotné přihlášení je pro tuto doménu deaktivováno.",
      "USER_NOT_FOUND": "Nepodařilo se nám najít účet spojený se zadanou e-mailovou adresou. Zkontroluj prosím svou e-mailovou adresu nebo kontaktuj správce systému."
    }
  },
  "sso-settings": {
    "enabled-success": "Jednotné přihlášení bylo aktivováno",
    "enable": "Zapnuto",
    "disabled-success": "Jednotné přihlášení bylo deaktivováno",
    "label": "Jednotné přihlášení",
    "modification-error": "Při konfiguraci jednotného přihlašování došlo k chybě. Prosím zkuste to znovu",
    "SSOSettings": "Nastavení jednotného přihlášení",
    "title": "Jednotné přihlášení"
  },
  "statistics": "Statistiky",
  "status": "Stav",
  "statusType": {
    "available": "Dostupný",
    "away": "Brzy se vrátím",
    "busy": "Obsazený",
    "dnd": "Nerušit",
    "hidden": "Zobrazit jako offline"
  },
  "stayLoggedIn": "Zůstat přihlášeni",
  "steady": "Doba trvání",
  "supervisor": "Supervizor",
  "switchboard": "Rozvaděč",
  "take-a-break": "Udělejte si přestávku",
  "target": "Cíl",
  "TelexNumber": "Telex",
  "time": "Čas",
  "timeout": "Překročen časový limit",
  "title": "Můj stav",
  "to": "Pro",
  "today": "Dnes",
  "tologin": "K přihlášení",
  "totallogintime": "Celková doba přihlášení",
  "TTYTDDTelephoneNumber": "Textový telefon",
  "type": "Druh",
  "unhold": "Získat zpět",
  "unknown": "Neznámý",
  "unknown-contact": "Neznámý kontakt",
  "unknownerror": "Neznámý chybový stav: {0}",
  "unknownversion": "Neznámá verze",
  "unsavedchanges": {
    "cancel": "Přizpůsobit změny",
    "content": "Existují neuložené změny. Přejete si je zrušit před odchodem?",
    "invalid-content": "Existují některé neuložené neplatné změny. Chcete je před odchodem vrátit?",
    "invalidmessage": "Neuložené změny nelze uložit",
    "revert": "Zahodit změny",
    "save": "Uložit",
    "valid-content": "Některé změny nejsou zachovány. Chcete je před odchodem uložit?",
    "validmessage": "Neuložené změny"
  },
  "user": {
    "changepassword": "Změnit heslo",
    "deletePhotoConfirmationMessage": "Opravdu chcete obrázek smazat?",
    "deletePhotoConfirmationTitle": "Profilový obrázek",
    "deleteprofilepicture": "Smazat profilový obrázek",
    "disablePhone": "Deaktivovat telefonii v softclientu",
    "extension": "Telefonní pobočka",
    "extensionpassword": "Heslo k telefonním pobočkám",
    "language": "Jazyk",
    "languages": {
      "cs": "Česky",
      "de": "Německy",
      "en": "Anglicky",
      "fr": "Francouzsky"
    },
    "newpassword": "Nové heslo",
    "newprofilepicture": "Nový profilový obrázek",
    "oldpassword": "Staré heslo",
    "passwordchangeerror": "To se nepodařilo. Zkontrolujte prosím své staré heslo a zkuste to znovu.",
    "passwordsdontmatch": "Hesla se neshodují",
    "passwordwaschanged": "Heslo změněno.",
    "profilepicture": "Profilový obrázek",
    "repeatnewpassword": "Zopakovat heslo",
    "uploadprofilepicture": "Nahrát",
    "useatleast8characters": "Heslo musí obsahovat alespoň 8 znaků.",
    "userprofile": "Profil"
  },
  "user-at-dot": "Uživatelské jméno musí obsahovat znak {'@'} a tečku „.“.",
  "user-cant-be-edited": "Zde nelze upravovat uživatele",
  "userhasnoextension": "Selhalo: Uživatel nemá přiřazenou žádnou telefonní pobočku.",
  "userLogin": "Uživatel",
  "username": "Uživatelské jméno",
  "userneedsdomain": "Selhalo: Uživatelské jméno musí obsahovat doménu.",
  "userpassword": "Nové heslo",
  "Users": "Uživatel",
  "validators": {
    "nonempty": "Nemůže být prázdný"
  },
  "video": "Video",
  "view-details": "Zobrazit podrobnosti",
  "voicemail-active": "Hlasová schránka je aktivní",
  "voicemail-language": "Jazyk hlasové schránky",
  "voicemail-password": "Heslo hlasové schránky",
  "voicemail-settings": "Hlasová schránka",
  "voicemails": "Nahrávky",
  "wait-time": "Doba čekání",
  "waiting": "Čekání",
  "waiting-time": "Doba čekání",
  "waitingMemberCount": "Fronta",
  "waittime": "Doba čekání",
  "warm": "Teplé",
  "warmtransfer": "Sdělit",
  "will-be-set-on-save": "Nastavuje se náhodně při ukládání",
  "work": "Práce",
  "wrap-up": "Následné zpracování",
  "wrongcredentials": "Selhalo: Uživatel a heslo se neshodují.",
  "wtgcloudpure": "WTG Cloud PURE",
  "yes-delete": "Ano, vymazat",
  "yesterday": "Včera"
}