{
  "accept": "Annehmen",
  "acceptedcalls": "Angenommene Gespräche",
  "active": "Aktiv",
  "add": "Hinzufügen",
  "add_notes": "Notiz hinzufügen",
  "add-contact-number": "Kontakt, Nummer hinzufügen",
  "add-user": "Benutzer hinzufügen",
  "addparticipant": "Teilnehmer hinzufügen",
  "addresslabel": {
    "work": "Arbeit"
  },
  "admin-portal": "Admin Portal",
  "admin-user-form": {
    "select-user": "Bitte wählen Sie einen Benutzer aus",
    "successfully-created": "Benutzer wurde erfolgreich erstellt.",
    "successfully-updated": "Benutzer wurde erfolgreich aktualisiert.",
    "user-doesnt-exist": "Dieser Benutzer existiert nicht",
    "validation-error": "Es sind Fehler aufgetreten. Bitte beheben Sie diese und versuchen Sie es erneut."
  },
  "agent": "Agent",
  "agent-status": {
    "all": "Alle",
    "available": "Verfügbar",
    "break": "Pause",
    "in-call": "Im Gespräch",
    "inactive": "Inaktiv",
    "logged-in": "Eingeloggt",
    "logged-out": "Abgemeldet",
    "wrap-up": "Nachbearbeitung"
  },
  "agents": "Agenten",
  "agentspace": "Agent Panel",
  "all": "Alle anzeigen",
  "all_contacts": "Alle Kontakte",
  "all-read": "Alle gelesen",
  "allContacts": "Alle Kontakte",
  "answered": "Beantwortet",
  "AssistantTelephoneNumber": "Assistent",
  "at-busy": "Bei Besetzt",
  "audio": {
    "audio": "Audio",
    "canteditoutputs": "Audioausgabe-Einstellungen können in diesem Browser leider nicht bearbeitet werden.",
    "defaultinput": "Default",
    "defaultoutput": "Default (Laufende Anrufe werden nicht zurückgesetzt)",
    "externalRingtone": "Klingelton extern",
    "inputs": "Eingabe",
    "internalRingtone": "Klingelton intern",
    "outputs": "Ausgabe",
    "ringingoutput": "Ausgabe klingeln",
    "ringonboth": "Auf beiden Geräten klingeln"
  },
  "autologinfailed": "Automatisches Login fehlgeschlagen",
  "available": "Verfügbar",
  "averagecallduration": "Durchschnittliche Gesprächszeit",
  "back": "Zurück",
  "backdrop_inactive_set_break": "Weil du keine Anrufe mehr angenommen hast, wurde dein Status auf Pause gesetzt. Du bist nicht aktiv und erhältst keine Anrufe mehr aus der Warteschleife. Der Pausegrund ist: Wegen Inaktivität auf Pause gesetzt.",
  "backdrop_return_to_active": "Um wieder aktiv zu werden, musst du deinen Status auf Angemeldet ändern.",
  "backdrop_supervisor_set_break": "Dein Supervisor hat deinen Status auf Pause geändert. Du bist nicht aktiv und erhältst keine Anrufe mehr aus der Warteschleife. Der Pausegrund ist: Vom Supervisor auf Pause gesetzt.",
  "backdrop_you_are_set_to_break": "Du wurdest auf Pause gesetzt.",
  "break": "Pause",
  "break_message": "Du machst gerade eine Pause",
  "break-comment": {
    "BACKOFFICE": "Backoffice",
    "BREAK": "Pause",
    "break-comment": "Pausengrund",
    "MEETING": "Besprechung",
    "select-prompt": "Pausengrund auswählen",
    "SUPERVISOR": "Supervisor"
  },
  "break-reason": "Pausen-Grund",
  "Business2TelephoneNumber": "Geschäftlich 2",
  "BusinessFaxNumber": "Fax geschäftlich",
  "BusinessTelephoneNumber": "Geschäftlich",
  "busy": "Besetzt",
  "call_history": "Anruf-Historie",
  "call-center": {
    "agent-activity": {
      "available": "Verfügbar",
      "break": "Pause",
      "in-call": "Im Gespräch",
      "in-wrapup": "Nachbereitung",
      "log-in": "Einloggen",
      "logged-in": "Eingeloggt",
      "logged-out": "Abgemeldet",
      "wrapup": "Nachbereitung"
    },
    "agents-in-queue": "Agenten in dieser Warteschleife",
    "call-analytics": {
      "abandoned": "Abgebrochen",
      "handled": "Angenommen",
      "in-call": "Im Gespräch",
      "waiting": "Wartend"
    },
    "direct-call": "Direkter Anruf",
    "no-logged-in-agents": "Derzeit sind keine Agenten in dieser Warteschleife angemeldet.",
    "queue": "Warteschlange",
    "tier": "Level",
    "todays-calls": "Heutige Anrufe"
  },
  "call-center-queues": {
    "abandonedResumeAllowed": "Verworfene wieder einreihen",
    "abandonedResumeAllowedToggleDescription": "Anrufer erlauben seine Position in der Warteschleife wieder einzunehmen.",
    "addAgent": "Agent auswählen",
    "addDescription": "Beschreibung hinzufügen",
    "addName": "Name hinzufügen",
    "addQueue": "Warteschleife hinzufügen",
    "addQueueError": "Problem beim Hinzufügen der Warteschleife aufgetreten",
    "addQueueSuccess": "Die Warteschleife wurde erfolgreich hinzugefügt.",
    "addSupervisor": "Supervisor auswählen",
    "agent-with-fewest-calls": "Agent mit dem wenigsten Anrufen",
    "agent-with-least-talk-time": "Agent mit niedrigster Gesprächszeit",
    "agents": "Agenten",
    "agentsSectionDescription": "Level ordnen Agenten eine Prioritätsreihenfolge innerhalb von Warteschleifen zu.",
    "announceFrequency": "Frequenz Warteansage",
    "announceFrequencyFieldDescription": "Nach wie vielen Sekunden die Warteansage wiederkehrend abgespielt werden soll.",
    "announceSound": "Warteansage",
    "announceSoundFieldDescription": "Die Warteansage, die dem Anrufer in wiederkehrenden Intervallen vorgespielt werden soll.",
    "availableLessThan": "Verfügbar weniger (als in %)",
    "call-center-queues": "Call Center Warteschleifen",
    "callerOutboundName": "Ausgehender Name",
    "callerOutboundNameDescription": "Geben Sie den ausgehenden Namen des externen Anrufers ein.",
    "callerOutboundNumber": "Ausgehende Nummer",
    "callerOutboundNumberDescription": "Geben Sie die ausgehende Nummer des externen Anrufers ein.",
    "callerPrefix": "Anruf Präfix",
    "callerPrefixFieldDescription": "Legen Sie ein Präfix fest, welches bei eingehenden Anrufern vor der Rufnummer angezeigt werden soll.",
    "cancel": "Abbrechen",
    "deleteQueue": "Warteschleife löschen",
    "deleteQueueConfirmationMessage": "Möchten Sie die Warteschleife \"{name}\" wirklich löschen?",
    "deleteQueueConfirmationTitle": "Call Center Warteschleifen",
    "description": "Beschreibung",
    "discardCall": "Anruf verwerfen",
    "discardCallFieldDescription": "Legen Sie fest nach wie vielen Sekunden der Anruf verworfen werden soll.",
    "editQueue": "Warteschleife bearbeiten",
    "editQueueError": "Problem beim Bearbeiten der Warteschleife aufgetreten",
    "editQueueSuccess": "Die Warteschleife wurde erfolgreich bearbeitet.",
    "extension": "Nebenstelle",
    "greeting": "Begrüßung",
    "inBreakMoreThan": "In Pause mehr als (in %)",
    "incomingCalls": "Anrufer (Warteschleife)",
    "level": "Level",
    "logged-queues": "Zugeordnete Warteschleifen",
    "loggedLessThan": "Angemeldet weniger als (in %)",
    "longest-idle-agent": "Längste Leerlaufzeit",
    "maxWaitingTime": "Maximale Wartezeit",
    "maxWaitingTimeFieldDescription": "Die Wartezeit in Sekunden, bevor die ausgewählte Aktion ausgeführt werden soll.",
    "maxWaitingTimeNoAgent": "Max. Wartezeit ohne angemeldeten Agenten",
    "maxWaitingTimeNoAgentFieldDescription": "Die Zeit, die ein Anrufer in der Warteschleife verbringt, bevor die ausgewählte Aktion ausgeführt werden soll.",
    "maxWaitingTimeNoAvailableAgent": "Max. Wartezeit ohne verfügbaren Agenten",
    "maxWaitingTimeNoAvailableAgentFieldDescription": "Die Zeit, die ein Anrufer in der Warteschleife verbringt, bevor die ausgewählte Aktion ausgeführt werden soll.",
    "musicOnHold": "Wartemusik",
    "name": "Name",
    "numberOfWaiting": "Anzahl der wartenden Anrufer",
    "orangeStart": "Orange ab",
    "order": "Reihenfolge",
    "pos": "Pos.",
    "queues": "Warteschleifen",
    "random": "Zufällig",
    "recording": "Aufnahme",
    "recordingToggleDescription": "Die Aufnahme speichern.",
    "redStart": "Rot ab",
    "removeQueueError": "Problem beim Löschen der Warteschleife aufgetreten",
    "removeQueueSuccess": "Die Warteschleife wurde erfolgreich gelöscht.",
    "ring-all": "Alle klingeln",
    "round-robin": "Rundlauf",
    "save": "Speichern",
    "search": "Suche nach Call Center Warteschleifen",
    "selectAction": "Aktion wählen",
    "selectExtension": "Nebenstelle wählen",
    "selectGreeting": "Begrüßung wählen",
    "selectMusicOnHold": "Wartemusik wählen",
    "selectOrder": "Reihenfolge wählen",
    "selectSound": "Ton wählen",
    "selectStrategy": "Strategie wählen",
    "selectTime": "Zeit wählen",
    "selectWaitingTime": "Wartezeit wählen",
    "sequentially-by-agent-order": "Sequentiell nach Agenten",
    "sequentially-by-next-agent-order": "Sequentiell nach nächstem Agenten",
    "setPrefix": "Präfix wählen",
    "strategy": "Strategie",
    "supervisor": "Supervisor",
    "supervisorSectionDescription": "Legen Sie einen oder mehrere Supervisoren für diese Warteschleife fest.",
    "thresholds": "Schwellenwerte",
    "thresholdsSectionDescription": "Legen Sie Schwellwerte für einen optischen Hinweis fest.",
    "tierLevel": "Level",
    "tierPosition": "Level Pos.",
    "tierRuleNoAgentNoWait": "Kein Agent, keine Wartezeit",
    "tierRuleNoAgentNoWaitToggleDescription": "Wenn kein Agent verfügbar, geht der Anrufer direkt in das nächste Level.",
    "tierRules": "Level-Regeln",
    "tierRulesToggleDescription": "Legen Sie fest, ob die Level-Regeln für die Warteschleife gelten sollen.",
    "tierRuleWaitMultiply": "Verlängerung der Level-Regel Wartezeit",
    "tierRuleWaitMultiplyToggleDescription": "Verlängerung der Wartezeit, wenn der Anrufer bis zum nächsten Level wartet.",
    "timeBaseValue": "Zeitbasiswert",
    "timeBaseValueFieldDescription": "Auswahl, ob die Zeit, die der Anrufer im System verbracht hat, als Wartezeit gezählt wird.",
    "timeout": "Zeitüberschreitung",
    "timeoutAction": "Aktion bei Zeitüberschreitung",
    "timeoutActionFieldDescription": "Legen Sie die Aktion fest, die ausgeführt werden soll, wenn einer der drei oberen Werte greift.",
    "top-down": "Von oben nach unten",
    "value": "Wert"
  },
  "call-detail": "Anruf Detail",
  "call-forward-settings": "Rufweiterleitung",
  "call-forwarding": {
    "at-busy": "Falls aktiviert, wird die aktive Mailbox der Nebenstelle überschrieben.",
    "no-answer": "Falls aktiviert, wird die aktive Mailbox überschrieben.",
    "not-registered": "Rufweiterleitung an neues Ziel, wenn ursprüngliches Ziel nicht erreichbar ist, bevor die Mailbox aktiviert wird.",
    "phone-number": "Leiten Sie alle Anrufe an das angegebene Ziel."
  },
  "call-group": {
    "active": "Aktiv",
    "confirm": "Bestätigen",
    "deleteGroupConfirmationMessage": "Möchten Sie die Gruppe wirklich löschen \"{name}\"?",
    "deleteGroupConfirmationTitle": "Gruppen",
    "enter-groups": "Geben Sie hier die Pickupgruppen des Nutzers ein.",
    "groups": "Gruppen",
    "no-confirmation": "Keine Bestätigung",
    "not-assigned": "Sie sind derzeit keiner Anrufgruppe zugewiesen"
  },
  "call-group-settings": "Pickupgruppen",
  "call-history": "Anrufer-Historie",
  "call-reason": "Grund für Anruf",
  "call-reasons": {
    "banking-information": "Bankverbindung",
    "customer-data-changed": "Änderung Kundendaten",
    "no-reason": "Kein Grund",
    "opening-time-inquiry": "Anfrage Öffnungszeiten",
    "order": "Bestellung",
    "product-inquiry": "Produktanfrage",
    "support": "Support"
  },
  "call-settings": {
    "AnnouncementManagement": "Ansagen-Management",
    "Blacklisting": "Schwarze Liste",
    "CallBroadcast": "Rufen Sie Broadcast an",
    "IVR": "IVR",
    "label": "Telefonie Einstellungen",
    "OpenTimes": "Feiertage / Öffnungszeiten",
    "ParkingFunction": "Parken Funktion",
    "Pickup": "Pickup",
    "RingGroups": "Sammelrufgruppen",
    "SwitchBoardSettings": "Vermittlungsplatz"
  },
  "CallbackTelephoneNumber": "Rückmeldung",
  "callcenter-settings": {
    "label": "Call Center Einstellungen",
    "QueuesSettings": "Call Center Warteschleifen",
    "Supervisor": "Call Center Supervisor"
  },
  "callduration": "{seconds} s telefoniert",
  "caller": "Anrufer",
  "callforward": {
    "all": "Rufumleitung alle",
    "alldescription": "Leiten Sie alle Anrufe an das angegebene Ziel.",
    "busy": "Bei Besetzt",
    "busydescription": "Falls aktiviert, wird die aktivierte Mailbox der Nebenstelle überschrieben.",
    "callforward": "Rufumleitung",
    "noanswer": "Keine Antwort",
    "noanswerdescription": "Falls aktiviert, wird die aktivierte Mailbox der Nebenstelle überschrieben.",
    "notregistered": "Nicht registriert",
    "notregistereddescription": "Rufweiterleitung an neues Ziel, wenn ursprüngliches Ziel nicht erreichbar ist, bevor die Mailbox aktiviert wird.",
    "target": "Ziel"
  },
  "calls": "Anrufe",
  "callscript": {
    "callscript": "Anrufskript",
    "removecallscript": "Entfernen",
    "selectcallscript": "Anrufskript auswählen",
    "testcallscript": "Testen"
  },
  "calltime": "Anrufzeit",
  "cancel": "Abbrechen",
  "canceled": "Abgebrochen",
  "CarTelephoneNumber": "Auto",
  "changelicense": {
    "cancel": "Abbrechen",
    "content": "Wir weisen Sie darauf hin, dass der Wechsel des Lizenztypen kaufmännische Auswirkungen mit sich bringen und zu Veränderungen der Kosten führen kann. Sind Sie sicher, dass Sie die Benutzerlizenz zu der \"{license}\" Lizenz wechseln möchten?",
    "save": "Ja, wechseln",
    "title": "Lizenz wechseln"
  },
  "changelog": "Change Log",
  "checkwtgcloud": "Meinen Sie wtg.cloud statt wtg.com?",
  "choose-agent-status": "Bitte wählen Sie den Status des Agenten.",
  "choose-break-reason": "Bitte wählen Sie den Grund für Ihre Pause.",
  "clear-selection": "Auswahl leeren",
  "close": "Schließen",
  "closewindowdespitecall": "Es gibt noch einen laufenden Anruf, trotzdem schließen?",
  "cold": "Kalt",
  "coldtransfer": "Weiterleiten",
  "company": "Firma",
  "conference-member": "Konferenz-Teilnehmer",
  "connect_to": "Vermitteln",
  "contact": "Kontakt",
  "contact-details": {
    "add": "Neuen Kontakt hinzufügen",
    "address": "Adresse",
    "contact-delete": "Möchten Sie diesen Kontakt löschen?",
    "contact-delete-title": "Löschen von Kontakten",
    "country": "Land",
    "edit": "Kontaktinformationen bearbeiten",
    "email": "E-Mail",
    "location": "Ort",
    "position": "Position",
    "postcode": "Postleitzahl",
    "title": "Kontaktdaten"
  },
  "contact-form": {
    "delete-error": "Kontakt konnte nicht gelöscht werden",
    "delete-success": "Kontakt erfolgreich gelöscht",
    "enterAddress": "Straße und Hausnummer eingeben",
    "enterCompany": "Firmenname eingeben",
    "enterCountry": "Ländernamen eingeben",
    "enterEmail": "E-Mail Adresse eingeben",
    "enterFirstname": "Name eingeben",
    "enterLastname": "Nachname eingeben",
    "enterLocation": "Ortsname eingeben",
    "enterMobile": "Handynummer eingeben",
    "enterPosition": "Position eingeben",
    "enterPostcode": "Postleitzahl eingeben",
    "enterWork": "Telefonnummer der Arbeitsstelle eingeben",
    "put-error": "Kontakt konnte nicht aktualisiert werden",
    "put-success": "Kontakt erfolgreich aktualisiert",
    "save-error": "Kontakt konnte nicht gespeichert werden",
    "save-success": "Kontakt erfolgreich gespeichert"
  },
  "contact-wtg": "Bitte wenden Sie sich an die WTG.",
  "contactinfo": "Kontaktdaten",
  "contacts": {
    "contacts": "Kontakte",
    "currentlyImported": "Anzahl importierter Kontakte",
    "importOutlookContacts": "Outlook-Kontakte importieren",
    "outlook": "Outlook-Kontakte",
    "outlookExplanation": "Kontakte, die über Outlook gepflegt sind und sich auf dem System befinden, können importiert werden. Die importierten Kontakte verbleiben nach einem Import auf dem System und sind nicht auf anderen Endgeräten verfügbar.",
    "refreshOutlookContacts": "Outlook-Kontakte aktualisieren",
    "removeOutlookContacts": "Outlook-Kontakte entfernen",
    "status": {
      "failure": "Fehler beim Importieren der Kontakte.",
      "running": "Kontakte werden importiert …",
      "success": "Kontakte erfolgreich importiert."
    }
  },
  "currentversion": "Aktuelle Version",
  "date": "Datum",
  "declined": "Abgelehnt",
  "delete": "Löschen",
  "devices-settings": {
    "label": "Endgeräte",
    "Provisoning": "Provisionierung",
    "Registration": "Registrierung"
  },
  "dialing": "Wählen",
  "dialpad": "Wählen",
  "direct": "Direkt",
  "diverted-by": "Umgeleitet von",
  "done": "Fertig",
  "duration": "Dauer",
  "edit": "Bearbeiten",
  "email-address": "E-Mail Adresse",
  "enabled": "Aktiv",
  "endcall": "Verlassen",
  "enter-new-message": "Eine neue Nachricht eingeben",
  "enternameornumber": "Nummer eingeben",
  "error": {
    "call-history-loading-error": "Beim Laden der Anrufliste ist ein Fehler aufgetreten.",
    "tokeninvalid": "Ihr Passwortreset ist leider abgelaufen. Bitte beantragen Sie ein neues über die Passwort-Vergessen-Funktion.",
    "TOO_MANY_CONTACTS": "Zu viele zugeordnete Kontakte",
    "TOO_MANY_DESTINATIONS": "Zu viele eingehende Rufnummern",
    "TOO_MANY_EXTENSIONS": "Zu viele zugeordnete Nebenstellen",
    "TOO_MANY_VOICEMAILS": "Zu viele zugeordnete Voicemails",
    "unknown": "Das hat leider nicht funktioniert, bitte versuchen Sie es nochmal.",
    "wronglink": "Dieser Passwort-Reset-Link ist fehlerhaft. Bitte schließen Sie diese Seite und klicken Sie nochmal auf den Link in Ihrer E-Mail."
  },
  "error-domain": "Bitte überprüfen Sie die Domain (den Teil hinter dem '{'@'}').",
  "error400": "Bitte überprüfen Sie ihren Benutzernamen und versuchen Sie es nochmal.",
  "error403": "Möglicherweise haben Sie zu häufig das Zurücksetzen des Passworts auf einmal beantragt.",
  "errorKey": "Fehler",
  "everyone-happy": "Alle sind glücklich.",
  "extension": "Nebenstelle",
  "extensionnumber": "Nebenstellen-Nummer",
  "extensionsettings": {
    "active": "Aktiv",
    "allowcallwaiting": "Anklopfen erlauben",
    "busyonbusy": "Busy-On-Busy",
    "busyonbusydecision": "Mehrere eingehende Anrufe",
    "callGroups": "Sammelrufgruppen",
    "exceptions": "Ausnahmen",
    "extensionsettings": "Leitungseinstellungen",
    "secretary": "Sekretär",
    "secretary-settings": "Sekretäreinstellungen"
  },
  "external": "Extern",
  "external-inbound-phone-number": "Eingehend",
  "external-outbound-phone-number": "Ausgehend",
  "externalextensionnumber": "Meine Nummer",
  "externalnumbers": "Externe Rufnummern",
  "failed": "Nicht erreichbar",
  "favoriteAdd": "Zu Favoriten hinzufügen",
  "favoriteRemove": "Aus Favoriten entfernen",
  "favorites": "Favoriten",
  "firstname": "Vorname",
  "followme": {
    "adddestination": "Hinzufügen",
    "delay": "Verzögerung",
    "delete": "Löschen",
    "deleteTargetConfirmationMessage": "Möchten Sie das Ziel wirklich löschen?",
    "deleteTargetConfirmationTitle": "Anrufweiterschaltung",
    "followme": "Anrufweiterschaltung",
    "ignorebusy": "Besetzt ignorieren",
    "ignorebusydescription": "Anruf unterbrechen, wenn ein Ziel besetzt ist.",
    "prompt": "Nachfragen",
    "promptoff": "Aus",
    "prompton": "Ein",
    "target": "Ziel",
    "timeout": "Abwurfzeit"
  },
  "forgotPassword": "Passwort vergessen?",
  "forward_to": "Weiterleiten",
  "fromconference": "aus Konferenz mit {0} Teilnehmern",
  "fromconferenceOptions": "aus Konferenz ohne Teilnehmer | aus Konferenz mit einem Teilnehmer | aus Konferenz mit {n} Teilnehmern",
  "globalContact": "Zentral verwalteter Kontakt",
  "globalContacts": "Zentrale Kontakte",
  "group": "Gruppe",
  "groupname": "Gruppenname",
  "hang-up": "Auflegen",
  "help": "Hilfsseite",
  "hide": "Ausblenden",
  "history": "Verlauf",
  "historytabs": {
    "all": "Alle",
    "inbound": "Eingehend",
    "missed": "Verpasst",
    "outbound": "Ausgehend"
  },
  "hold": "Halten",
  "holding": "Halten",
  "home": "Home",
  "Home2TelephoneNumber": "Privat 2",
  "HomeFaxNumber": "Fax privat",
  "HomeTelephoneNumber": "Privat",
  "homeview": {
    "no-missed-calls": "Es gibt keine neuen verpassten Anrufe.",
    "up-to-date": "Sie sind auf dem neuesten Stand."
  },
  "hotkey": {
    "directcall": "Direkt anrufen",
    "enableHotkey": "Hotkey einschalten",
    "hotkey": "Tastenkürzel",
    "keybidingPermissions": "Berechtigungen für Tastenkombinationen erlauben",
    "movetoforeground": "In den Vordergrund bringen"
  },
  "hourabbrev": "std",
  "hung-up": "Aufgelegt",
  "importedFrom": "Importiert aus",
  "in-call": "Im Gespräch",
  "inactive": "Inaktiv",
  "inactiveUser": "Fehler: Benutzer ist nicht aktiv.",
  "inbound": "Eingehend",
  "inbound-calls": "Eingehende Anrufe",
  "incomingcall": "Eingehender Anruf",
  "incomingcallfrom": "Eingehender Anruf von {0}",
  "incomingcalls": "Keine eingehenden Anrufe | Eingehender Anruf | {n} eingehende Anrufe",
  "information": {
    "clientversion": "Client-Version",
    "information": "Client-Informationen",
    "showchangelog": "Gesamtes Change Log anzeigen"
  },
  "insertnameornumber": "Name oder Nummer eingeben",
  "integration-settings": {
    "azure-client-id": "Anwendungs-ID (Client)",
    "azure-client-secret": "Geheimer Clientschlüssel-Wert",
    "azure-tenant-id": "Verzeichnis-ID (Mandant)",
    "AzureSettings": "Azure Integration",
    "configuration": "Azure-Konfiguration",
    "delete-config-msg": "Möchten Sie die Azure-Konfiguration wirklich löschen?",
    "description": "Durch die Eingabe der Daten aktivieren Sie automatisch die Azure-Integration, welche die Synchronisation der Anwesenheit in MS Teams und die Kontaktsynchronisation umfasst.",
    "label": "Integration",
    "title": "Azure-Integrationseinstellungen"
  },
  "internal": "Intern",
  "internalextensionnumber": "Durchwahl",
  "invalid-data": "Ungültige Daten angegeben. Bitte überprüfen und erneut versuchen",
  "invalid-uuid": "Der Wert sollte im UUID-Format sein",
  "invalidphonenumber": "Ungültige Rufnummer.",
  "ISDNNumber": "ISDN",
  "ivr": {
    "active": "Aktiv",
    "addDescription": "Beschreibung hinzufügen",
    "addExtension": "Nebenstelle hinzufügen",
    "addIVR": "IVR hinzufügen",
    "addName": "Name hinzufügen",
    "callerPrefix": "Anrufer Präfix",
    "callerPrefixFieldDescription": "Legen Sie ein Präfix fest, welches bei eingehenden Anrufen vor der Rufnummer angezeigt werden soll.",
    "cancel": "Abbrechen",
    "defineCallOptions": "Definieren Sie Anruferoptionen für das IVR-Menü.",
    "delayTimeBetweenInput": "Verzögerungszeit zwischen Eingabe",
    "delayTimeBetweenInputFieldDescription": "Zeit in Millisekunden, die zwischen den Ziffern gewartet wird.",
    "deleteIVR": "IVR löschen",
    "deleteIvrConfirmationMessage": "Möchten Sie den IVR \"{Name}\" wirklich löschen?",
    "deleteIvrConfirmationTitle": "IVR Menü",
    "description": "Beschreibung",
    "digitLength": "Ziffernlänge",
    "digitLengthFieldDescription": "Maximal zulässige Anzahl von Ziffern.",
    "editIVR": "IVR Bearbeiten",
    "enterTime": "Zeit eingeben",
    "enterTimeFieldDescription": "Zeit in Millisekunden, die nach dem Abspielen der Ansage oder des Bestätigungsmakros gewartet werden soll.",
    "exitAction": "Beendigungsaktion",
    "extension": "Nebenstelle",
    "greeting": "Begrüßung",
    "invalidOptionSound": "Ton bei ungültiger Option",
    "invalidOptionSoundFieldDescription": "Wird abgespielt, wernn eine ungültige Option gewählt wird.",
    "ivr": "IVR",
    "IVRs": "Sprachmenüs",
    "language": "Sprache",
    "long": "Lang",
    "longGreetingDescription": "Die lange Begrüßung wird beim Aufrufen des Menüs abgespielt.",
    "maxFailures": "Maximale Fehlangaben",
    "maxFailuresFieldDescription": "Maximale Anzahl an falscher Eingaben, die ein Kunde machen kann, bevor der Anruf beendet wird.",
    "maximumTimeouts": "Maximale Zeitüberschreitungen",
    "maximumTimeoutsFieldDescription": "Maximale Anzahl von Wiederholungsversuchen vor dem Beenden.",
    "name": "Name",
    "option": "Option",
    "options": "Optionen",
    "order": "Reihenfolge",
    "parentMenu": "Übergeordnetes Menü",
    "pin": "PIN",
    "pinFieldDescription": "Optionale PIN um den Zugang zum Menü zu sichern.",
    "recordingAnnouncement": "Die Ansagen müssen über den Master-Admin der WTG eingespielt werden. Wenn Sie neue Audiodateien hinzufügen möchten, schicken Sie diese bitte an: pure.tsc{'@'}wtg.com",
    "ringback": "Freizeichenton",
    "ringbackFieldDescription": "Legen Sie fest, was der Anrufer hört, während das Ziel angerufen wird.",
    "save": "Speichern",
    "search": "Suche nach IVR",
    "selectAction": "Aktion auswählen",
    "selectAnnouncement": "Ansage auswählen",
    "selectExitActionFieldDescription": "Wählen Sie die Beendigungsaktion aus, die durchgeführt werden soll, wenn die IVR beendet wird.",
    "selectLanguage": "Sprache auswählen",
    "selectParentMenu": "Übergeordnetes Menü auswählen",
    "selectPrefix": "Präfix wählen",
    "selectSound": "Ton wählen",
    "selectTarget": "Ziel auswählen",
    "setDelayTimeBetweenInput": "Verzögerungszeit zwischen Eingabe",
    "setDigitLength": "Ziffernlänge einstellen",
    "setMaxFailures": "Maximale Fehlangaben",
    "setMaximumTimeouts": "Maximale Zeitüberschreitungen festlegen",
    "setPin": "Set PIN",
    "short": "Kurz",
    "shortGreetingDescription": "Die kurze Begrüßung wird bei der Rückkehr zum Menü abgespielt.",
    "soundOnMenuExit": "Ton beim Verlassen des Menüs",
    "soundOnMenuExitFieldDescription": "Wird beim Verlassen des Menüs abgespielt.",
    "target": "Ziel",
    "time": "Zeit",
    "timeout": "Zeitüberschreitung"
  },
  "keypad": "Nummernfeld",
  "label-active": "Angemeldet",
  "label-idle": "Pause",
  "language": "Sprache",
  "languages": "Sprachen",
  "lastname": "Nachname",
  "licence": "Lizenz",
  "loading": "Wird geladen …",
  "logged-out": "Abgemeldet",
  "logging_in_message": "Sie werden angemeldet",
  "logging_out_message": "Sie werden abgemeldet",
  "logIn": "Anmelden",
  "logout": "Abmelden",
  "logs": {
    "delete-logs": "Logs löschen",
    "deleted": "Logs wurden gelöscht.",
    "disclaimer": "Logs werden nur lokal gespeichert und beim Beenden des Clients oder des Browsers gelöscht.\nMit 'Logs senden' werden sie der WTG zur Verfügung gestellt.",
    "failure": "Fehler beim Senden der Logs.",
    "logs": "Logs",
    "note": "Notiz zu Logs hinzufügen",
    "oldest-log": "Ältestes Log",
    "running": "Logs werden gesendet …",
    "send-logs": "Logs senden",
    "success": "Logs wurden gesendet."
  },
  "medialibrary-settings": {
    "accessible-for": "Verwendbar für",
    "add-audio-title": "Neue Audiodatei hinzufügen",
    "audio-category-placeholder": "Kategorie auswählen",
    "audio-language-placeholder": "Sprache auswählen",
    "audio-name": "Name",
    "audio-name-placeholder": "Audioname hinzufügen",
    "audio-usable-for-placeholder": "Verwendung auswählen",
    "audio-users-placeholder": "Benutzer auswählen",
    "AudioLibrarySettings": "Audio",
    "browse-files": "Dateien durchsuchen",
    "cancel": "Abbrechen",
    "categories": {
      "GREETING": "Grußwort",
      "IVR": "IVR-Ansage",
      "MUSIC_ON_HOLD": "Warteschleifenmusik",
      "OTHER": "Andere",
      "RINGTONE": "Klingelton"
    },
    "category": "Kategorie",
    "columns": {
      "category": "Kategorie",
      "language": "Sprache",
      "name": "Name",
      "type": "Typ",
      "upload-time": "Upload-Zeit",
      "uploaded-by": "Hochgeladen von",
      "usable-for": "Verwendbar für",
      "used-in": "Verwendet in"
    },
    "confirm-delete": "Ja, löschen",
    "delete-audio-title": "Audiodatei löschen",
    "delete-confirmation-msg": "Sind Sie sicher, dass Sie die Audiodatei \"{name}\" löschen möchten?",
    "delete-greeting-dependencies-msg": "Diese Audiodatei wird derzeit verwendet. Sind Sie sicher, dass Sie sie löschen möchten? Wenn ja, wird die Datei gelöscht und durch die Standard-Audiodatei ersetzt.",
    "delete-ivr-dependencies-msg": "Dieses Audio wird derzeit verwendet, Abhängigkeiten müssen zuerst entfernt werden.",
    "drag-drop": "Dateien hierher ziehen und ablegen",
    "edit-audio-title": "Audiodatei bearbeiten",
    "edit-success": "Audiodatei erfolgreich aktualisiert",
    "file": "Datei",
    "header": "Audio-Dateien",
    "label": "Audio-Bibliothek",
    "language": "Sprache",
    "legal-disclaimer": "Indem Sie urheberrechtlich geschützte Musik, audiovisuelle Inhalte, Tonaufnahmen, Fotografien, Kunstwerke und andere Formen von Medien auf WTG Cloud PURE hochladen, verwenden oder anzeigen, garantieren Sie der WTG, dass Sie die Erlaubnis und/oder eine Lizenz zur Verwendung dieser Inhalte haben. Wenn Sie dies ohne Erlaubnis oder Lizenz des Eigentümers tun, verstoßen Sie gegen das Urheberrechtsgesetz und können zur Entfernung dieser Inhalte führen.",
    "or-choose": "oder",
    "save-success": "Audiodatei erfolgreich erstellt",
    "search": "Suche nach Audiodateien",
    "title": "Audio-Bibliothek",
    "usage-type": {
      "ivr": "IVR-Menü",
      "queue": "Call-Center-Warteschlange",
      "ring_group": "Ringgruppe"
    },
    "used-in-title": "Verwendet in",
    "user": "Benutzer",
    "visibility": {
      "personal": "Persönlich",
      "public": "Öffentlich"
    }
  },
  "members": "Anrufer (Warteschleife)",
  "merge": "Vermitteln abschließen",
  "min": "Min.",
  "minuteabbrev": "min",
  "missedcall": "Verpasst",
  "missedcalls": "Verpasste Anrufe",
  "MobileTelephoneNumber": "Mobiltelefon",
  "mobiletitel": "Telefon",
  "more": "Mehr",
  "mute": "Stumm schalten",
  "my_favorites": "Meine Favoriten",
  "myActiveQueues": "Meine aktiven Warteschleifen",
  "name": "Name",
  "newpassword": "Neues Passwort",
  "newuser": "Neuer Benutzer",
  "no": "Nein",
  "no-data": "Keine Daten verfügbar",
  "no_agent": "Du hast keinen zugeordneten Agenten",
  "no-answer": "Keine Antwort",
  "no-number-available": "Keine Nummer verfügbar",
  "no-one-waiting": "Keiner wartet.",
  "noActiveQueues": "Keine aktiven Warteschleifen",
  "nomissedcalls": "Sie haben nichts verpasst.",
  "not-available": "Keine Nummer vorhanden",
  "not-registered": "Nicht registriert",
  "not-working": "Das hat leider nicht funktioniert.",
  "notes": "Notizen",
  "notice": "Notizen",
  "notifications": {
    "banner": "Banner",
    "banner_info": "Bei Eingang eines Anrufes in der Pickup Gruppe Benachrichtigungs-Banner anzeigen",
    "notifications": "Mitteilungen",
    "pickup": "Pickup",
    "sound_status": "Sound",
    "sound_status_info": "Bei Eingang eines Anrufes in der Pickup Gruppe Sound wiedergeben"
  },
  "notLoggedOut": "Alle angemeldeten",
  "number": "Nummer",
  "numpad": "Wähltastatur",
  "offline_message": "Du bist abgemeldet",
  "onbreak": "In Pause",
  "organization": "Unternehmen",
  "OtherFaxNumber": "Weiteres Fax",
  "OtherTelephoneNumber": "Weitere",
  "outlookContact": "Aus Outlook importiert",
  "Overview": "Übersicht",
  "park": "Parken",
  "park_call": "Anruf Parken",
  "park-call": "Anruf parken",
  "parked-calls": "Geparkte Anrufe",
  "parking": "Parken",
  "participant": "Teilnehmer",
  "participants": "Teilnehmer",
  "password": "Passwort",
  "passwordhastocontain8chars": "Das Passwort muss mindestens 8 Zeichen enthalten.",
  "passwordreset-success-msg": "Um das Passwort zurückzusetzen, wurde an die hinterlegte Adresse eine E-Mail versendet. Bitte überprüfen Sie Ihr Postfach.",
  "passwordsdontmatch": "Die Passwörter stimmen nicht überein.",
  "personalContact": "Persönlicher Kontakt",
  "personalContacts": "Persönliche Kontakte",
  "phone": "Telefon",
  "phone-number": "Rufumleitung",
  "phonebook-form": {
    "add": "Telefonbuchgruppe hinzufügen",
    "delete-content": "Möchten Sie diese Telefonbuchgruppe löschen?",
    "delete-error": "Telefonbuchgruppe konnte nicht gelöscht werden",
    "delete-success": "Telefonbuchgruppe erfolgreich gelöscht",
    "delete-title": "Löschen der Telefonbuchgruppe",
    "edit": "Telefonbuchgruppe bearbeiten",
    "name": "Gruppenname",
    "put-error": "Telefonbuchgruppe konnte nicht aktualisiert werden",
    "put-success": "Telefonbuchgruppe erfolgreich aktualisiert",
    "save-error": "Telefonbuchgruppe konnte nicht gespeichert werden",
    "save-success": "Telefonbuchgruppe erfolgreich gespeichert",
    "search-contact": "Kontakt suchen"
  },
  "phonebook-groups": {
    "contacts-amount": "Anzahl der Kontakte",
    "global-empty": "Noch keine zentralen Gruppen hinzugefügt!",
    "global-title": "Zentrale Gruppen",
    "personal-empty": "Noch keine persönlichen Gruppen hinzugefügt! Klicken Sie auf Hinzufügen, um eine zu erstellen.",
    "personal-title": "Persönliche Gruppen"
  },
  "phonebook-settings": {
    "CentralContacts": "Zentrale Kontakte",
    "CentralGroups": "Zentrale Gruppen",
    "label": "Telefonbuch"
  },
  "phonelabel": {
    "fax": "Fax",
    "mobile": "Mobil",
    "other": "Andere",
    "work": "Arbeit"
  },
  "pickup": "Pickup",
  "pickupGroups": {
    "addPickupGroup": "Pickup Gruppe hinzufügen",
    "deleteGroupConfirmationMessage": "Möchten Sie die Pickup-Gruppe wirklich löschen \"{name}\"?",
    "deleteGroupConfirmationTitle": "Pickup Gruppe",
    "editPickupGroup": "Pickup Gruppe bearbeiten",
    "members": "Mitglieder",
    "members-number": "Anzahl der Gruppenmitglieder",
    "nameTaken": "Dieser Name ist schon vergeben!",
    "noMembers": "Die Mitgliederliste darf nicht leer sein!",
    "notBlank": "Die Eingabe darf nicht leer sein!",
    "numberOfMembers": "Zahl der Mitglieder",
    "pickupGroupsHeader": "Pickup Gruppen",
    "search": "Suche nach Pickup Gruppen"
  },
  "position": "Position",
  "post-processing-time": "Nachbearbeitungszeit",
  "previousversion": "Vorherige Version",
  "PrimaryTelephoneNumber": "Haupttelefon",
  "profile-data": "Profil-Daten",
  "pureContact": "PURE-Kontakt",
  "pureContacts": "PURE-Kontakte",
  "queue": "Warteschleife",
  "queues": "Warteschleifen",
  "queues-waiting": "Wartende",
  "RadioTelephoneNumber": "Funkruf",
  "recordings": "Aufnahmen",
  "referred-by": "Weitergeleitet von",
  "reject": "Ablehnen",
  "repeatpassword": "Passwort wiederholen",
  "repeatuserpassword": "Passwort wiederholen",
  "replayed": "Beantwortet",
  "request_supervisor": "Supervisor anfordern",
  "request-password-reset-header": "Passwort vergessen?",
  "requests_supervisor": "Supervisor-Anfrage",
  "reset-password": "Passwort zurücksetzen",
  "resetpassword": "Passwort zurücksetzen",
  "resetsuccessmessage": "Das Passwort wurde zurückgesetzt.",
  "resetsuccesstitle": "Passwort wurde zurückgesetzt",
  "restriction-errors": {
    "at-least-one-target": "Mindestens eine Zieloption muss hinzugefügt werden",
    "duplicate": "Dieser Wert ist schon für den Nutzer {user} vergeben",
    "empty": "Darf nicht leer sein",
    "exceeded-quota": "Überschrittene Quote für Benutzer",
    "excluded-value-list-errors": {
      "CALL_CENTER_QUEUE": "Diese Nummer wird schon für eine Callcenter-Warteschleife verwendet",
      "DESTINATION": "Diese Nummer wird schon für eine Destination verwendet",
      "EXTENSION": "Diese Nummer wird bereits von einem anderen Benutzer verwendet",
      "IVR_MENU": "Diese Nummer wird schon für ein IVR-Menü verwendet",
      "other": "Diese Nummer wird schon woanders verwendet",
      "RING_GROUP": "Diese Nummer wird bereits für eine Anrufgruppe verwendet",
      "TIME_CONDITION": "Diese Nummer wird schon für eine Time-Condition verwendet"
    },
    "file-too-large": "Datei ist zu groß. Maximale Größe beträgt {sizeLimit} MB",
    "invalid-file-type": "Ungültiger Dateityp",
    "passwords-not-matching": "Die Passwörter müssen übereinstimmen",
    "regex-errors": {
      "DOMAIN_NUMBER_RESTRICTION": "Diese Telefonnummer ist für Ihren Account nicht erlaubt",
      "EMAIL": "Ungültige E-Mail-Adresse",
      "EXTENSION_NUMBER": "Darf nur Ziffern enthalten und nicht mit einer 0 anfangen",
      "other": "Falsches Format",
      "PHONE_NUMBER": "Muss eine Telefonnummer sein und mit + anfangen",
      "USERNAME": "Nutzername muss aus Buchstaben, Ziffern, -, _ oder . bestehen.",
      "VOICEMAIL_PASSWORD": "Muss aus 6 bis 16 Ziffern bestehen"
    },
    "too-short": "Muss mindestens {minimum} Zeichen enthalten",
    "unique-extension": "Die Erweiterung {extension} wird bereits verwendet.",
    "unique-inbound-number": "Die eingehende Nummer {inboundNumber} ist bereits vergeben.",
    "unique-username": "Der Benutzername {username} wird bereits verwendet.",
    "unknown-error": "Validierungsfehler",
    "wrong-format": "Falsches Format"
  },
  "return-to-login": "Zurück zur Anmeldung",
  "revert": "Änderungen verwerfen",
  "ring-groups": {
    "accept": "Annehmen",
    "active": "Aktiv",
    "add": "Hinzufügen",
    "addCallGroup": "Gruppe hinzufügen",
    "addDescription": "Beschreibung hinzufügen",
    "addExtension": "Nebenstelle wählen",
    "addName": "Name auswählen",
    "addTarget": "Ziel hinzufügen",
    "busyTone": "Freizeichenton",
    "busyToneFieldDescription": "Legen Sie fest, was der Anrufer hört, während das Ziel angerufen wird.",
    "callerIdName": "Anrufer-ID Name",
    "callerIdNameFieldDescription": "Legen Sie den Namen der Anrufer-ID für ausgehende externe Anrufe fest.",
    "callerIdNumber": "Anrufer-ID-Nummer",
    "callerIdNumberFieldDescription": "Legen Sie die Anrufer-ID-Nummer für ausgehende externe Anrufe fest.",
    "callGroupForward": "Sammelrufgruppe umleiten",
    "callGroupForwardFieldDescription": "Umleitung der gesamten Sammelrufgruppe an ein ausgewähltes Ziel.",
    "callGroupForwarding": "Sammelrufgruppen Weiterleitung",
    "callGroupForwardingFieldDescription": "Weiterleitung einer angerufenen Rufgruppe an ein anderes Ziel.",
    "callGroups": "Sammelrufgruppen",
    "callTimeout": "Zeitüberschreitung eines Anrufs",
    "cancel": "Abbrechen",
    "chooseCallTimeout": "Zeitüberschreitung wählen",
    "delay": "Verzögern",
    "deleteCallGroup": "Gruppe löschen",
    "description": "Beschreibung",
    "editCallGroup": "Gruppe Bearbeiten",
    "emailAddress": "E-Mail Adresse",
    "enterprise": "Unternehmen",
    "extension": "Nebenstelle",
    "feesForwarding": "Weiterleitung von Gebühren",
    "followMe": "Anrufweiterschaltung",
    "followMeFieldDescription": "Wählen Sie aus, ob die Anrufweiterschaltung innerhalb der Gruppe angewendet werden soll.",
    "forwarding": "Rufumleitung",
    "forwardingFieldDescription": "Wählen Sie aus, ob die Rufumleitung innerhalb der Gruppe angewendet werden soll.",
    "greeting": "Begrüßung",
    "groupMembers": "Gruppen Mitglieder",
    "groups": "Gruppen",
    "missedCalls": "Verpasste Anrufe",
    "missedCallsFieldDescription": "Wahlen Sie aus ob jemand per E-Mail benachrichtigt werden soll.",
    "name": "Name",
    "random": "Zufällig",
    "recall": "Rückruf",
    "recallFieldDescription": "Legt fest, was der Anrufer hört, während das Ziel angerufen wird.",
    "rollover": "Rollover",
    "save": "Speichern",
    "search": "Suche nach Sammelrufgruppen",
    "selectCallerIdName": "Anrufer-ID Name wählen",
    "selectCallerIdNumber": "Anrufer-ID-Nummer wählen",
    "selectContact": "Kontakt auswählen",
    "selectFeesForwarding": "Weiterleitung von Gebühren wählen",
    "selectGreeting": "Begrüßung wählen",
    "selectNumber": "Nummer wählen",
    "selectSound": "Ton wählen",
    "selectStrategy": "Strategie wählen",
    "selectTarget": "Ziel wählen",
    "sequence": "Sequenz",
    "simultaneous": "Gleichzeitige",
    "strategy": "Strategie",
    "target": "Ziel",
    "targetByTimeLimit": "Ziel nach Zeitlimit",
    "targets": "Ziele",
    "targetsSectionDescription": "Fügen Sie der Ringgruppe Ziele und Parameter hinzu.",
    "timeLimit": "Zeitlimit",
    "typeOfNotification": "Art der Benachrichtigung"
  },
  "ringduration": "{seconds} s geklingelt",
  "save": "Speichern",
  "savepassword": "Passwort speichern",
  "search": "Suche",
  "search-for-contact": "Nach Kontakt suchen",
  "search-for-user": "Suche nach einem Nutzer",
  "search-name": "Suche",
  "sec": "Sek.",
  "secondabbrev": "s",
  "secretary-function": {
    "active": "Aktiv",
    "target": "Mein Sekretär"
  },
  "secretary-function-settings": "Sekretäreinstellungen",
  "section_header_second": "KONTAKTE",
  "select-break-reason": "Pausengrund auswählen",
  "serverunreachable": "Fehlgeschlagen: Der Server {0} ist nicht erreichbar.",
  "service-level": "Service Level",
  "set-agent-status": "Status des Agenten festlegen",
  "set-status": "Status setzen",
  "settings": "Einstellungen",
  "show_all": "Alle anzeigen",
  "signWithMicrosoft": "Anmelden mit Microsoft-Konto",
  "softclient-settings": {
    "label": "Soft-Client Einstellungen",
    "MultiField": "Multi-Feld",
    "Shortcuts": "Tastaturkürzel"
  },
  "sound-error": "Mikrofon-Probleme",
  "soundoutputsettings": "Ausgabeeinstellungen",
  "speaker": "Lautsprecher",
  "sso": {
    "error": {
      "ACCESS_TOKEN_NOT_AVAILABLE": "Zugriffstoken nicht verfügbar.",
      "GENERAL_ERROR": "Während der SSO-Authentifizierung ist ein unerwarteter Fehler aufgetreten",
      "MULTIPLE_ACCOUNTS": "Für diese E-Mail-Adresse wurden mehrere Benutzer gefunden. Bitte wenden Sie sich an den Administrator.",
      "SSO_DISABLED": "Single Sign-On ist für diese Domain deaktiviert.",
      "USER_NOT_FOUND": "Wir konnten kein Konto finden, das mit der angegebenen E-Mail-Adresse verknüpft ist. Bitte überprüfen Sie Ihre E-Mail-Adresse oder wenden Sie sich an den Systemadministrator."
    }
  },
  "sso-settings": {
    "enabled-success": "Single Sign-On wurde aktiviert",
    "enable": "Aktiviert",
    "disabled-success": "Single Sign-On wurde deaktiviert",
    "label": "Single Sign-On",
    "modification-error": "Bei der Konfiguration von Single Sign-On ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    "SSOSettings": "Single Sign-On Einstellungen",
    "title": "Single Sign-On"
  },
  "statistics": "Statistiken",
  "status": "Status",
  "statusType": {
    "available": "Verfügbar",
    "away": "Bin gleich zurück",
    "busy": "Beschäftigt",
    "dnd": "Nicht stören",
    "hidden": "Als offline anzeigen"
  },
  "stayLoggedIn": "Angemeldet bleiben",
  "steady": "Dauer",
  "supervisor": "Supervisor",
  "switchboard": "Vermittlungsplatz",
  "take-a-break": "Eine Pause einlegen",
  "target": "Ziel",
  "TelexNumber": "Telex",
  "time": "Zeit",
  "timeout": "Zeitüberschreitung",
  "title": "Mein Status",
  "to": "An",
  "today": "Heute",
  "tologin": "Zur Anmeldung",
  "totallogintime": "Anmeldezeit in Summe",
  "TTYTDDTelephoneNumber": "Texttelefon",
  "type": "Art",
  "unhold": "Zurückholen",
  "unknown": "Unbekannt",
  "unknown-contact": "Unbekannter Kontakt",
  "unknownerror": "Unbekannter Fehlerzustand: {0}",
  "unknownversion": "Unbekannte Version",
  "unsavedchanges": {
    "cancel": "Änderungen anpassen",
    "content": "Es gibt einige nicht gespeicherte Änderungen. Möchten Sie diese rückgängig machen, bevor Sie die Seite verlassen?",
    "invalid-content": "Es gibt einige ungespeicherte ungültige Änderungen. Möchten Sie diese vor Ihrer Abreise rückgängig machen?",
    "invalidmessage": "Ungültige Änderungen können nicht gespeichert werden",
    "revert": "Änderungen verwerfen",
    "save": "Speichern",
    "valid-content": "Es gibt noch einige ungespeicherte Änderungen. Möchten Sie diese vor Ihrer Abreise speichern?",
    "validmessage": "Ungespeicherte Änderungen"
  },
  "user": {
    "changepassword": "Passwort ändern",
    "deletePhotoConfirmationMessage": "Möchten Sie das Bild wirklich löschen?",
    "deletePhotoConfirmationTitle": "Profilbild",
    "deleteprofilepicture": "Profilbild löschen",
    "disablePhone": "Telefonie im Softclient deaktivieren",
    "extension": "Nebenstelle",
    "extensionpassword": "Nebenstellen-Passwort",
    "language": "Sprache",
    "languages": {
      "cs": "Tschechisch",
      "de": "Deutsch",
      "en": "Englisch",
      "fr": "Französisch"
    },
    "newpassword": "Neues Passwort",
    "newprofilepicture": "Neues Profilbild",
    "oldpassword": "Altes Passwort",
    "passwordchangeerror": "Das hat leider nicht funktioniert. Bitte überprüfen Sie ihr altes Passwort und versuchen Sie es nochmal.",
    "passwordsdontmatch": "Passwörter stimmen nicht überein",
    "passwordwaschanged": "Passwort geändert.",
    "profilepicture": "Profilbild",
    "repeatnewpassword": "Passwort wiederholen",
    "uploadprofilepicture": "Hochladen",
    "useatleast8characters": "Das Passwort muss mindestens 8 Zeichen enthalten.",
    "userprofile": "Profil"
  },
  "user-at-dot": "Der Benutzername muss ein {'@'} und einen . enthalten",
  "user-cant-be-edited": "Benutzer kann hier nicht bearbeitet werden",
  "userhasnoextension": "Fehlgeschlagen: Der Nutzer hat keine Nebenstelle zugeordnet.",
  "userLogin": "Benutzer",
  "username": "Benutzername",
  "userneedsdomain": "Fehlgeschlagen: Der Benutzername muss eine Domain enthalten.",
  "userpassword": "Neues Passwort",
  "Users": "Benutzer",
  "validators": {
    "nonempty": "Kann nicht leer sein"
  },
  "video": "Video",
  "view-details": "Details anzeigen",
  "voicemail-active": "Voicemail aktiv",
  "voicemail-language": "Sprache der Voicemail",
  "voicemail-password": "Voicemail-Passwort",
  "voicemail-settings": "Voicemail",
  "voicemails": "Sprachnachrichten",
  "wait-time": "Wartezeit",
  "waiting": "Warten",
  "waiting-time": "Wartezeit",
  "waitingMemberCount": "Warteschleife",
  "waittime": "Wartezeit",
  "warm": "Warm",
  "warmtransfer": "Vermitteln",
  "will-be-set-on-save": "Wird beim Speichern zufällig gesetzt",
  "work": "Arbeit",
  "wrap-up": "Nachbearbeitung",
  "wrongcredentials": "Fehlgeschlagen: Benutzer und Passwort stimmen nicht überein.",
  "wtgcloudpure": "WTG Cloud PURE",
  "yes-delete": "Ja, löschen",
  "yesterday": "Gestern"
}