// TODO refactor to obejct or enum

// Call-Related Endpoints
export const CALL_HISTORY = '/callhistory.php';
export const TRANSFER = '/transfer.php';
export const PARKED_CALLS = '/parking.php';
// todo verify if the name make sense
export const CALL_CONFERENCE = '/conference.php';
export const CALLCENTER_CALL_HISTORY = '/v1/dashboard/call_history.php';

// SSO & Login
export const LOGIN = '/v2/login';
export const SSO_INTEGRATION = '/v2/sso-enabled';

// Azure Integration
export const AZURE_INTEGRATION = '/admin/azure_integration.php';

// Audio & IVR
export const AUDIO_LIBRARY_ADMIN = '/v2/audio';
export const IVR_MENUS = '/admin/ivr_menus.php';
export const IVR_MENU_LANGUAGE_OPTIONS = `${IVR_MENUS}?get=language_options`;
export const ADMIN_RECORDINGS = '/admin/recordings.php';
export const ADMIN_PHRASES = '/admin/phrases.php';
export const ADMIN_MUSIC_ON_HOLD = '/admin/music_on_hold.php';
export const VOICEMAIL = '/voicemail.php';

// Call Center
export const CALL_CENTER = '/callcenter.php';
export const CALL_CENTER_QUEUES = '/admin/call_center_queues.php';
export const CALL_CENTER_AGENTS = '/admin/call_center_agents.php';
export const CALL_CENTER_ANALYTICS = {
  CALL_ANALYTICS: '/v1/dashboard/callcenter_call_analytics.php',
  AGENTS_ANALYTICS: '/v1/dashboard/callcenter_agents_analytics.php',
};

// Admin Entities
export const ADMIN_USERS = '/admin/users.php';
export const ADMIN_VALIDATORS = '/admin/validators.php';
export const ADMIN_EXTENSIONS = '/admin/extensions.php';
export const ADMIN_RING_GROUPS = '/admin/ring_groups.php';
export const ADMIN_RING_GROUP = '/admin/user_ring_groups.php';
export const ADMIN_PICKUP_GROUPS = '/admin/pickup_groups.php';
export const USERS_LICENSES = '/admin/licenses.php';

// Contacts
export const CONTACTS = '/v2/contacts';
export const PERSONAL_CONTACTS = '/v2/contacts/personal';
export const GLOBAL_CONTACTS = '/v2/contacts/global';
export const CONTACT_AVATAR = '/contact_attachments.php';
export const PHONEBOOK_GROUPS = '/v2/phonebook-groups';
export const PERSONAL_PHONEBOOK_GROUPS = `${PHONEBOOK_GROUPS}/personal`;
export const GLOBAL_PHONEBOOK_GROUPS = `${PHONEBOOK_GROUPS}/global`;

// User & Domain Settings
export const USERS = '/users.php';
export const DOMAIN_OPTIONS = '/domain_options.php';
export const EXTENSIONS_SETTINGS = '/extension_settings.php';
export const SECRETARY_STATUS = '/is_secretary.php';
export const CALL_FORWARD_EXTENSION_SETTINGS = '/extension_forwards.php';
export const FOLLOW_ME_EXTENSION_SETTINGS = '/extension_follow_me.php';
export const FAVORITES = '/favorites.php';
export const ACCOUNT = '/account.php';
export const HOMEPAGES = '/homepages.php';
export const CAPABILITIES = '/capabilities.php';

// Logs & Reports
export const LOG_REPORT = '/logreport.php';
