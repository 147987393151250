{
  "accept": "Accepter",
  "acceptedcalls": "Conversations acceptées",
  "active": "Actif",
  "add": "Ajouter",
  "add_notes": "Ajouter une note",
  "add-contact-number": "Ajouter numéro, contact",
  "add-user": "Ajouter un utilisateur",
  "addparticipant": "Ajouter un participant",
  "addresslabel": {
    "work": "Travail"
  },
  "admin-portal": "Portail administrateur",
  "admin-user-form": {
    "select-user": "Veuillez sélectionner un utilisateur",
    "successfully-created": "L'utilisateur a été créé avec succès.",
    "successfully-updated": "L'utilisateur a été mis à jour avec succès.",
    "user-doesnt-exist": "Cet utilisateur n'existe pas",
    "validation-error": "Des erreurs se sont glissées dans votre formulaire. Veuillez les corriger et réessayer."
  },
  "agent": "Agents",
  "agent-status": {
    "all": "Tous",
    "available": "Disponible",
    "break": "En Pause",
    "in-call": "En Conversation",
    "inactive": "Inactif",
    "logged-in": "Connecté",
    "logged-out": "Déconnecté",
    "wrap-up": "Post-traitement"
  },
  "agents": "Agents",
  "agentspace": "Panneau pour agents",
  "all": "Voir tous",
  "all_contacts": "Tous les contacts",
  "all-read": "Tous lus",
  "allContacts": "Tous les contacts",
  "answered": "Répondu",
  "AssistantTelephoneNumber": "Assistant",
  "at-busy": "En cas de ligne occupée",
  "audio": {
    "audio": "Audio",
    "canteditoutputs": "Les paramètres de sortie audio ne peuvent malheureusement pas être modifiés dans ce navigateur.",
    "defaultinput": "Par défaut",
    "defaultoutput": "Par défaut (les appels en cours ne sont pas réinitialisés)",
    "externalRingtone": "Sonnerie d'appel externe",
    "inputs": "Entrée",
    "internalRingtone": "Sonnerie d'appel interne",
    "outputs": "Sortie",
    "ringingoutput": "Sonner la sortie",
    "ringonboth": "Sonner sur les deux appareils"
  },
  "autologinfailed": "Échec de la connexion automatique",
  "available": "Disponible",
  "averagecallduration": "Durée moyenne de la conversation",
  "back": "Retour",
  "backdrop_inactive_set_break": "Comme vous n’avez plus accepté d’appel, votre statut a été défini sur « Pause ». Vous n’êtes pas actif et ne recevez plus aucun appel de la file d’attente. Le motif de la pause est le suivant : Défini sur « Pause » en raison de l’inactivité.",
  "backdrop_return_to_active": "Pour être à nouveau actif, vous devez modifier votre statut sur « Connecté ».",
  "backdrop_supervisor_set_break": "Votre superviseur a passé votre statut sur « Pause ». Vous n’êtes pas actif et ne recevez plus aucun appel de la file d’attente. Le motif de la pause est le suivant : Défini sur « Pause » par le superviseur.",
  "backdrop_you_are_set_to_break": "Vous avez été mis sur « Pause ».",
  "break": "Pause",
  "break_message": "Vous êtes en pause",
  "break-comment": {
    "BACKOFFICE": "Backoffice",
    "BREAK": "Pause",
    "break-comment": "Motif de la pause",
    "MEETING": "Conversation",
    "select-prompt": "Choisir le motif de la pause",
    "SUPERVISOR": "Superviseur"
  },
  "break-reason": "Motif de la pause",
  "Business2TelephoneNumber": "Professionnel 2",
  "BusinessFaxNumber": "Fax professionnel",
  "BusinessTelephoneNumber": "Professionnel",
  "busy": "Occupé",
  "call_history": "Historique d’appel",
  "call-center": {
    "agent-activity": {
      "available": "Disponible",
      "break": "En Pause",
      "in-call": "En Appel",
      "in-wrapup": "En Suivi",
      "log-in": "Se Connecter",
      "logged-in": "Connecté",
      "logged-out": "Déconnecté",
      "wrapup": "Suivi"
    },
    "agents-in-queue": "Agents dans cette file d'attente",
    "call-analytics": {
      "abandoned": "Annulé",
      "handled": "Accepté",
      "in-call": "En appel",
      "waiting": "Attente"
    },
    "direct-call": "Appel direct",
    "no-logged-in-agents": "Il n'y a actuellement aucun agent enregistré dans cette file d'attente.",
    "queue": "File d'attente",
    "tier": "Niveau",
    "todays-calls": "Appels d'aujourd'hui"
  },
  "call-center-queues": {
    "abandonedResumeAllowed": "Reprendre l'appelant abandonné",
    "abandonedResumeAllowedToggleDescription": "Permettre à l'appelant de reprendre sa place dans la file d'attente.",
    "addAgent": "Sélectionner l'agent",
    "addDescription": "Ajouter une description",
    "addName": "Ajouter un nom",
    "addQueue": "Ajouter une file d'attente",
    "addQueueError": "Un problème est survenu lors de l'ajout de la file d'attente",
    "addQueueSuccess": "L'ajout de la file d'attente a été effectué avec succès.",
    "addSupervisor": "Sélectionner le superviseur",
    "agent-with-fewest-calls": "Agent avec le moins d'appels",
    "agent-with-least-talk-time": "Agent avec le temps de parole le plus court",
    "agents": "Agents",
    "agentsSectionDescription": "Les niveaux affectent les agents aux files d'attente.",
    "announceFrequency": "Fréquence d'annonce",
    "announceFrequencyFieldDescription": "Après combien de secondes le message d'attente doit être diffusé de manière répétée.",
    "announceSound": "Son d'annonce",
    "announceSoundFieldDescription": "Le message d'attente à diffuser à l'appelant à intervalles réguliers.",
    "availableLessThan": "Disponible moins de (en %)",
    "call-center-queues": "Files d'attente des centres d'appel",
    "callerOutboundName": "Nom du destinataire",
    "callerOutboundNameDescription": "Entrez le nom de l'appelant externe.",
    "callerOutboundNumber": "Numéro sortant",
    "callerOutboundNumberDescription": "Entrez le numéro de sortie de l'appelant externe.",
    "callerPrefix": "Préfixe de l'appelant",
    "callerPrefixFieldDescription": "Spécifiez un préfixe qui doit s'afficher devant le numéro de téléphone pour les appelants.",
    "cancel": "Supprimer les modifications",
    "deleteQueue": "Supprimer la file d'attente",
    "deleteQueueConfirmationMessage": "Voulez-vous vraiment supprimer la file d'attente \"{name}\" ?",
    "deleteQueueConfirmationTitle": "Files d'attente des centres d'appels",
    "description": "Description",
    "discardCall": "Abandonner l'appelant",
    "discardCallFieldDescription": "Spécifiez après combien de secondes l'appel doit être rejeté.",
    "editQueue": "Editer la file d'attente",
    "editQueueError": "Un problème est survenu lors de l'édition de la file d'attente",
    "editQueueSuccess": "La file d'attente a été modifiée avec succès.",
    "extension": "Extension",
    "greeting": "Message d'accueil",
    "inBreakMoreThan": "En pause plus de (en %)",
    "incomingCalls": "Appelant (file d’attente)",
    "level": "Niveau",
    "logged-queues": "Files d'attente attribuées",
    "loggedLessThan": "Connecté moins de (en %)",
    "longest-idle-agent": "L'agent en pause le plus longtemps",
    "maxWaitingTime": "Temps d'attente maximum",
    "maxWaitingTimeFieldDescription": "Temps d'attente en secondes avant l'exécution de l'action.",
    "maxWaitingTimeNoAgent": "Temps d'attente maximum sans agent",
    "maxWaitingTimeNoAgentFieldDescription": "Le temps qu'un appelant passe dans la file d'attente avant que l'action sélectionnée ne soit programmée.",
    "maxWaitingTimeNoAvailableAgent": "Temps d'attente maximal sans agent disponible",
    "maxWaitingTimeNoAvailableAgentFieldDescription": "Le temps qu'un appelant passe dans la file d'attente avant que l'action sélectionnée ne soit programmée.",
    "musicOnHold": "Musique d'attente",
    "name": "Nom",
    "numberOfWaiting": "Nombre d'appelants en attente",
    "orangeStart": "Orange de",
    "order": "Commande",
    "pos": "Position",
    "queues": "Files d'attente",
    "random": "Au hasard",
    "recording": "Enregistrer",
    "recordingToggleDescription": "Sauvegarder l'enregistrement.",
    "redStart": "Rouge de",
    "removeQueueError": "Un problème est survenu lors de la suppression de la file d'attente",
    "removeQueueSuccess": "La file d'attente a été supprimée avec succès.",
    "ring-all": "Sonnerie générale",
    "round-robin": "Tour par tour",
    "save": "Enregistrer",
    "search": "Recherche de files d'attente pour les centres d'appels",
    "selectAction": "Sélectionner l'action de temps d’éjection",
    "selectExtension": "Sélectionner l'extension",
    "selectGreeting": "Sélectionner le message d'accueil",
    "selectMusicOnHold": "Sélectionner la musique d'attente",
    "selectOrder": "Sélectionner la commande",
    "selectSound": "Sélectionner le son",
    "selectStrategy": "Sélectionner une stratégie",
    "selectTime": "Sélectionner le temps",
    "selectWaitingTime": "Définir le temps d'attente maximum",
    "sequentially-by-agent-order": "Séquentiellement par ordre des agents",
    "sequentially-by-next-agent-order": "Séquentiellement par ordre du prochain agent",
    "setPrefix": "Définir le préfixe",
    "strategy": "Stratégie",
    "supervisor": "Superviseur",
    "supervisorSectionDescription": "Sélectionnez un ou plusieurs superviseurs pour cette file d'attente.",
    "thresholds": "Seuil",
    "thresholdsSectionDescription": "Définir des seuils pour une alerte visuelle.",
    "tierLevel": "Niveau",
    "tierPosition": "Position de niveau",
    "tierRuleNoAgentNoWait": "Pas d'agent, pas de temps d'attente",
    "tierRuleNoAgentNoWaitToggleDescription": "Si aucun agent n'est disponible, l'appelant passe directement au niveau suivant.",
    "tierRules": "Règles de niveau",
    "tierRulesToggleDescription": "Indiquer si les règles de niveau doivent s'appliquer à la file d'attente.",
    "tierRuleWaitMultiply": "Prolongation du délai d'attente de la règle de l'échelon",
    "tierRuleWaitMultiplyToggleDescription": "Prolongation du temps d'attente si l'appelant attend le niveau suivant.",
    "timeBaseValue": "Score de base de temps",
    "timeBaseValueFieldDescription": "Permet de déterminer si le temps passé par l'appelant dans le système est compté comme temps d'attente.",
    "timeout": "Temps d’éjection",
    "timeoutAction": "Action de temps d’éjection",
    "timeoutActionFieldDescription": "Définir l'action à effectuer lorsque le temps d'attente maximum est atteint.",
    "top-down": "De haut en bas",
    "value": "Valeur"
  },
  "call-detail": "Détail de l’appel",
  "call-forward-settings": "Transfert d’appel",
  "call-forwarding": {
    "at-busy": "Si ce paramètre est activé, la messagerie vocale activée du poste secondaire est remplacée.",
    "no-answer": "Si ce paramètre est activé, la messagerie vocale activée est remplacée.",
    "not-registered": "Transfert d’appel vers une nouvelle destination si la destination initiale n’est pas joignable avant l’activation de la messagerie vocale.",
    "phone-number": "Déviez tous les appels vers la destination indiquée."
  },
  "call-group": {
    "active": "Actif",
    "confirm": "Confirmer",
    "deleteGroupConfirmationMessage": "Voulez-vous vraiment supprimer le groupe \"{name}\" ?",
    "deleteGroupConfirmationTitle": "Groupe",
    "enter-groups": "Saisissez ici les groupes de reprise de l’utilisateur.",
    "groups": "Groupe",
    "no-confirmation": "Pas de confirmation",
    "not-assigned": "Vous n'êtes actuellement affecté à aucun groupe d'appels"
  },
  "call-group-settings": "Groupes de reprise",
  "call-history": "Historique de l’appelant",
  "call-reason": "Raison de l’appel",
  "call-reasons": {
    "banking-information": "Coordonnées bancaires",
    "customer-data-changed": "Modification des données client",
    "no-reason": "Aucun motif",
    "opening-time-inquiry": "Demande des heures d’ouverture",
    "order": "Commande",
    "product-inquiry": "Demande de produit",
    "support": "Assistance"
  },
  "call-settings": {
    "AnnouncementManagement": "Gestion des annonces",
    "Blacklisting": "Mise sur liste noire",
    "CallBroadcast": "Diffusion d’appels",
    "IVR": "IVR",
    "label": "Paramètres téléphoniques",
    "OpenTimes": "Jours fériés / Heures d’ouverture",
    "ParkingFunction": "Fonction « Mise en attente »",
    "Pickup": "Reprise",
    "RingGroups": "Groupes d’appels collectifs",
    "SwitchBoardSettings": "Poste de transfert"
  },
  "CallbackTelephoneNumber": "Réponse",
  "callcenter-settings": {
    "label": "Paramètres du centre d’appels",
    "QueuesSettings": "Files d’attente du centre d’appels",
    "Supervisor": "Superviseur du centre d’appels"
  },
  "callduration": "{seconds} s de temps d’appel",
  "caller": "Appelant",
  "callforward": {
    "all": "Déviation de tous les appels",
    "alldescription": "Déviez tous les appels vers la destination indiquée.",
    "busy": "En cas de ligne occupée",
    "busydescription": "La boîte vocale de l'extension sera écrasée.",
    "callforward": "Déviation d’appel",
    "noanswer": "Aucune réponse",
    "noanswerdescription": "La boîte vocale de l'extension sera écrasée.",
    "notregistered": "Non enregistré",
    "notregistereddescription": "Transfert d’appel vers une nouvelle destination si la destination initiale n’est pas joignable avant l’activation de la messagerie vocale.",
    "target": "Destination"
  },
  "calls": "Appels",
  "callscript": {
    "callscript": "Script d’appel",
    "removecallscript": "Supprimer",
    "selectcallscript": "Sélectionner le script d’appel",
    "testcallscript": "Tester"
  },
  "calltime": "Heure d’appel",
  "cancel": "Annuler",
  "canceled": "Annulé",
  "CarTelephoneNumber": "Voiture",
  "changelicense": {
    "cancel": "Annuler",
    "content": "Nous attirons votre attention sur le fait que le changement de type de licence peut avoir des conséquences commerciales et entraîner des modifications des coûts. Êtes-vous sûr de vouloir changer de licence utilisateur pour la licence \"{license}\"?",
    "save": "Oui, changer",
    "title": "Changer de licence"
  },
  "changelog": "Journal des changements",
  "checkwtgcloud": "Voulez-vous dire wtg.cloud au lieu de wtg.com ?",
  "choose-agent-status": "Veuillez choisir le statut de l'agent.",
  "choose-break-reason": "Veuillez choisir la raison de votre pause.",
  "clear-selection": "Vider la sélection",
  "close": "Fermer",
  "closewindowdespitecall": "Il y a encore un appel en cours, le fermer quand même ?",
  "cold": "Froid",
  "coldtransfer": "Transfert à froid",
  "company": "Société",
  "conference-member": "Participant(s) à la conférence",
  "connect_to": "Transférer",
  "contact": "Contact",
  "contact-details": {
    "add": "Ajouter un nouveau contact",
    "address": "Adresse",
    "contact-delete": "Souhaitez-vous supprimer ce contact ?",
    "contact-delete-title": "Suppression de contact",
    "country": "Pays",
    "edit": "Modifier les coordonnées",
    "email": "E-mail",
    "location": "Localisation",
    "position": "Position",
    "postcode": "Code postal",
    "title": "Détails de contact"
  },
  "contact-form": {
    "delete-error": "Le contact n'a pas pu être supprimé",
    "delete-success": "Contact supprimé avec succès",
    "enterAddress": "Saisir la rue et le numéro",
    "enterCompany": "Saisir le nom de l'entreprise",
    "enterCountry": "Saisir le nom du pays",
    "enterEmail": "Saisir l'adresse électronique",
    "enterFirstname": "Saisir le prénom",
    "enterLastname": "Saisir le nom de famille",
    "enterLocation": "Saisir le nom du lieu",
    "enterMobile": "Saisir le numéro de téléphone mobile",
    "enterPosition": "Saisir la position",
    "enterPostcode": "Saisir le code postal",
    "enterWork": "Saisir le numéro de téléphone au travail",
    "put-error": "Le contact n'a pas pu être mis à jour",
    "put-success": "Contact mis à jour avec succès",
    "save-error": "Le contact n'a pas pu être sauvegardé",
    "save-success": "Contact enregistré avec succès"
  },
  "contact-wtg": "Veuillez vous adresser à WTG.",
  "contactinfo": "Coordonnées",
  "contacts": {
    "contacts": "Contacts",
    "currentlyImported": "Nombre de contacts importés",
    "importOutlookContacts": "Importer les contacts Outlook",
    "outlook": "Contacts Outlook",
    "outlookExplanation": "Les contacts gérés par Outlook et se trouvant sur le système peuvent être importés. Les contacts importés restent sur le système après l’importation et ne sont pas disponibles sur d’autres appareils.",
    "refreshOutlookContacts": "Actualiser les contacts Outlook",
    "removeOutlookContacts": "Supprimer les contacts Outlook",
    "status": {
      "failure": "Erreur lors de l’importation des contacts.",
      "running": "Importer des contacts …",
      "success": "Contacts importés avec succès."
    }
  },
  "currentversion": "Version actuelle",
  "date": "Date",
  "declined": "Refusé",
  "delete": "Supprimer",
  "devices-settings": {
    "label": "Appareils terminaux",
    "Provisoning": "Commissionnement",
    "Registration": "Enregistrement"
  },
  "dialing": "Sélectionner",
  "dialpad": "Cadran",
  "direct": "Direct",
  "diverted-by": "Transmis par",
  "done": "Prêt",
  "duration": "Durée",
  "edit": "Modifier",
  "email-address": "Adresse e-mail",
  "enabled": "Actif",
  "endcall": "Quitter",
  "enter-new-message": "Saisir un nouveau message",
  "enternameornumber": "Saisir le numéro",
  "error": {
    "call-history-loading-error": "Une erreur s'est produite lors du chargement de l'historique des appels.",
    "tokeninvalid": "Votre mot de passe a malheureusement expiré. Veuillez demander un nouveau mot de passe en utilisant la fonction d’oubli du mot de passe.",
    "TOO_MANY_CONTACTS": "Trop de contacts attribués",
    "TOO_MANY_DESTINATIONS": "Trop de numéros de téléphone entrants",
    "TOO_MANY_EXTENSIONS": "Trop de postes secondaires attribués",
    "TOO_MANY_VOICEMAILS": "Trop de messageries vocales attribuées",
    "unknown": "Malheureusement, cela n’a pas fonctionné, veuillez réessayer.",
    "wronglink": "Ce lien de réinitialisation du mot de passe est erroné. Veuillez fermer cette page et cliquer une nouvelle fois sur le lien figurant dans votre e-mail."
  },
  "error-domain": "Veuillez vérifier le domaine (partie derrière le « {'@'} »).",
  "error400": "Veuillez vérifier votre nom d’utilisateur et réessayer.",
  "error403": "Vous avez peut-être demandé trop de fois la réinitialisation du mot de passe.",
  "errorKey": "Erreur",
  "everyone-happy": "Tout le monde est satisfait.",
  "extension": "Poste secondaire",
  "extensionnumber": "Numéro de poste secondaire",
  "extensionsettings": {
    "active": "Actif",
    "allowcallwaiting": "Autoriser les appels en attente",
    "busyonbusy": "Busy-On-Busy",
    "busyonbusydecision": "Plusieurs appels entrants",
    "callGroups": "Groupes d'appels",
    "exceptions": "Exceptions",
    "extensionsettings": "Paramètres de la ligne",
    "secretary": "Secrétariat",
    "secretary-settings": "Paramètres du secrétariat"
  },
  "external": "Externe",
  "external-inbound-phone-number": "Entrant",
  "external-outbound-phone-number": "Sortant",
  "externalextensionnumber": "Mon numéro",
  "externalnumbers": "Numéros de téléphone externes",
  "failed": "Non joignable",
  "favoriteAdd": "Ajouter aux favoris",
  "favoriteRemove": "Retirer des favoris",
  "favorites": "Favoris",
  "firstname": "Prénom",
  "followme": {
    "adddestination": "Ajouter",
    "delay": "Retard",
    "delete": "Supprimer",
    "deleteTargetConfirmationMessage": "Voulez-vous vraiment supprimer la cible ?",
    "deleteTargetConfirmationTitle": "Renvoi d’appel",
    "followme": "Renvoi d’appel",
    "ignorebusy": "Ignorer la ligne occupée",
    "ignorebusydescription": "Interrompre l’appel lorsqu’une destination est occupée.",
    "prompt": "Demandes",
    "promptoff": "Désactivé",
    "prompton": "Entrant",
    "target": "Destination",
    "timeout": "Temps d’éjection"
  },
  "forgotPassword": "Mot de passe oublié ?",
  "forward_to": "Faire suivre à",
  "fromconference": "de la conférence avec {0} participants",
  "fromconferenceOptions": "de la conférence sans participants | de la conférence avec un participant | de la conférence avec {n} participants",
  "globalContact": "Contact géré de manière centralisée",
  "globalContacts": "Contacts centraux",
  "group": "Groupe",
  "groupname": "Nom de groupe",
  "hang-up": "Raccrocher",
  "help": "Page d’aide",
  "hide": "Masquer",
  "history": "Évolution",
  "historytabs": {
    "all": "Tous",
    "inbound": "Entrant",
    "missed": "Manqué",
    "outbound": "Sortant"
  },
  "hold": "Mettre en attente",
  "holding": "Mettre en attente",
  "home": "Accueil",
  "Home2TelephoneNumber": "Privé 2",
  "HomeFaxNumber": "Fax privé",
  "HomeTelephoneNumber": "Privé",
  "homeview": {
    "no-missed-calls": "Il n'y a pas de nouveaux appels manqués.",
    "up-to-date": "Vous êtes à jour."
  },
  "hotkey": {
    "directcall": "Appeler directement",
    "enableHotkey": "Activer la touche de raccourci",
    "hotkey": "Raccourci clavier",
    "keybidingPermissions": "Autoriser les permissions pour les raccourcis clavier",
    "movetoforeground": "Mettre au premier plan"
  },
  "hourabbrev": "h",
  "hung-up": "Raccroché",
  "importedFrom": "Importé depuis",
  "in-call": "En conversation",
  "inactive": "Inactif",
  "inactiveUser": "Erreur : L'utilisateur n'est pas actif.",
  "inbound": "Entrant",
  "inbound-calls": "Appels entrants",
  "incomingcall": "Appel entrant",
  "incomingcallfrom": "Appel entrant de {0}",
  "incomingcalls": "Aucun appel entrant | Appel entrant | {n} appels entrants",
  "information": {
    "clientversion": "Version client",
    "information": "Informations client",
    "showchangelog": "Afficher l’ensemble du journal des changements"
  },
  "insertnameornumber": "Saisir le nom ou le numéro",
  "integration-settings": {
    "azure-client-id": "ID d'application (client)",
    "azure-client-secret": "Valeur du secret client",
    "azure-tenant-id": "ID de répertoire (locataire)",
    "AzureSettings": "Intégration Azure",
    "configuration": "Configuration Azure",
    "delete-config-msg": "Voulez-vous vraiment supprimer la configuration Azure ?",
    "description": "En saisissant les données, vous activez automatiquement l'intégration Azure, qui comprend la synchronisation de la présence dans MS Teams et la synchronisation des contacts.",
    "label": "Intégration",
    "title": "Paramètres d'intégration Azure"
  },
  "internal": "Interne",
  "internalextensionnumber": "Ligne directe",
  "invalid-data": "Données invalides fournies. Veuillez vérifier et réessayer",
  "invalid-uuid": "La valeur doit être au format UUID",
  "invalidphonenumber": "Numéro de téléphone non valable.",
  "ISDNNumber": "ISDN",
  "ivr": {
    "active": "Actif",
    "addDescription": "Ajouter une description",
    "addExtension": "Ajouter une extension",
    "addIVR": "Ajouter un IVR",
    "addName": "Ajouter un nom",
    "callerPrefix": "Préfixe de l'appelant",
    "callerPrefixFieldDescription": "Définir un préfixe sur le nom de l'appelant.",
    "cancel": "Annuler",
    "defineCallOptions": "Définir les options d'appel pour le menu IVR.",
    "delayTimeBetweenInput": "Délai entre les saisies",
    "delayTimeBetweenInputFieldDescription": "Le nombre de millisecondes à attendre entre les chiffres.",
    "deleteIVR": "Supprimer un IVR",
    "deleteIvrConfirmationMessage": "Voulez-vous vraiment supprimer l'IVR \"{name}\" ?",
    "deleteIvrConfirmationTitle": "IVR Menu",
    "description": "Description",
    "digitLength": "Longueur des chiffres",
    "digitLengthFieldDescription": "Nombre maximal de chiffres autorisés.",
    "editIVR": "Éditer un IVR",
    "enterTime": "Définir le délai",
    "enterTimeFieldDescription": "Le nombre de millisecondes à attendre après la diffusion de la salutation ou de la macro de confirmation.",
    "exitAction": "Action de sortie",
    "extension": "Extension",
    "greeting": "Salutation",
    "invalidOptionSound": "Son d'option invalide",
    "invalidOptionSoundFieldDescription": "Joué lorsque qu'une option invalide est choisie.",
    "ivr": "IVR",
    "IVRs": "Menu IVR",
    "language": "Langue",
    "long": "Long",
    "longGreetingDescription": "La salutation longue est jouée lors de l'entrée dans le menu.",
    "maxFailures": "Échecs maximaux",
    "maxFailuresFieldDescription": "Nombre maximal de tentatives avant la sortie.",
    "maximumTimeouts": "Délais max.",
    "maximumTimeoutsFieldDescription": "Nombre maximal de délais avant la sortie.",
    "name": "Nom",
    "option": "Option",
    "options": "Options",
    "order": "Ordre",
    "parentMenu": "Menu parent",
    "pin": "PIN",
    "pinFieldDescription": "Numéro PIN facultatif pour sécuriser l'accès au menu.",
    "recordingAnnouncement": "Les annonces doivent être ajoutées via le WTG Master Admin. Si vous souhaitez ajouter de nouveaux fichiers audio, veuillez les envoyer à : pure.tsc{'@'}wtg.com",
    "ringback": "Son de retour",
    "ringbackFieldDescription": "Définit ce que l'appelant entendra pendant que la destination est appelée.",
    "save": "Enregistrer",
    "search": "Recherche pour IVR",
    "selectAction": "Sélectionner une action",
    "selectAnnouncement": "Sélectionner la salutation",
    "selectExitActionFieldDescription": "Sélectionner l'action de sortie à effectuer si l'IVR quitte.",
    "selectLanguage": "Sélectionner la langue",
    "selectParentMenu": "Sélectionner le menu parent",
    "selectPrefix": "Sélectionner le préfixe",
    "selectSound": "Sélectionner le son",
    "selectTarget": "Sélectionner la cible",
    "setDelayTimeBetweenInput": "Définir le délai entre les saisies",
    "setDigitLength": "Définir la longueur des chiffres",
    "setMaxFailures": "Définir les échecs max.",
    "setMaximumTimeouts": "Définir les délais max.",
    "setPin": "Définir un PIN",
    "short": "Court",
    "shortGreetingDescription": "La salutation courte est jouée lors du retour au menu.",
    "soundOnMenuExit": "Son lors de la sortie du menu",
    "soundOnMenuExitFieldDescription": "Joué lors de la sortie du menu.",
    "target": "Cible",
    "time": "Temps",
    "timeout": "Délai"
  },
  "keypad": "Champ de numérotation",
  "label-active": "Connecté",
  "label-idle": "En pause",
  "language": "Langue",
  "languages": "Langues",
  "lastname": "Nom",
  "licence": "Licence",
  "loading": "Chargement …",
  "logged-out": "Déconnecté",
  "logging_in_message": "Vous êtes connecté",
  "logging_out_message": "Vous êtes déconnecté",
  "logIn": "Se connecter",
  "logout": "Déconnexion",
  "logs": {
    "delete-logs": "Supprimer les journaux",
    "deleted": "Les journaux ont été supprimés.",
    "disclaimer": "Les journaux ne sont enregistrés que localement et supprimés lorsque le client ou le navigateur est fermé.\n« Envoyer les journaux » permet de les mettre à disposition de WTG.",
    "failure": "Erreur lors de l’envoi des journaux.",
    "logs": "Journaux",
    "note": "Ajouter une note aux journaux",
    "oldest-log": "Journal le plus ancien",
    "running": "Envoi des journaux …",
    "send-logs": "Envoyer les journaux",
    "success": "Des journaux ont été envoyés."
  },
  "medialibrary-settings": {
    "accessible-for": "Utilisable pour",
    "add-audio-title": "Ajouter un nouveau fichier audio",
    "audio-category-placeholder": "Sélectionner une catégorie",
    "audio-language-placeholder": "Sélection de la langue",
    "audio-name": "Nom",
    "audio-name-placeholder": "Ajouter un nom de fichier audio",
    "audio-usable-for-placeholder": "Sélectionner l'utilisation",
    "audio-users-placeholder": "Sélectionner l'utilisateur",
    "AudioLibrarySettings": "Audio",
    "browse-files": "Parcourir les fichiers",
    "cancel": "Annuler",
    "categories": {
      "GREETING": "Salutation",
      "IVR": "Annonce IVR",
      "MUSIC_ON_HOLD": "Musique d'attente",
      "OTHER": "Autres",
      "RINGTONE": "Sonnerie"
    },
    "category": "Catégorie",
    "columns": {
      "category": "Catégorie",
      "language": "Langue",
      "name": "Nom",
      "type": "Type",
      "upload-time": "Temps de chargement",
      "uploaded-by": "Téléchargé par",
      "usable-for": "Utilisable pour",
      "used-in": "Utilisé dans"
    },
    "confirm-delete": "Oui, supprimer",
    "delete-audio-title": "Supprimer un fichier audio",
    "delete-confirmation-msg": "Êtes-vous sûr de vouloir supprimer le fichier audio \"{name}\"?",
    "delete-greeting-dependencies-msg": "Ce fichier audio est actuellement utilisé. Êtes-vous sûr de vouloir le supprimer? Si oui, le fichier est supprimé et remplacé par le fichier audio par défaut.",
    "delete-ivr-dependencies-msg": "Cet audio est actuellement en cours d'utilisation, les dépendances doivent d'abord être supprimées.",
    "drag-drop": "Glisser-déposer des fichiers ici",
    "edit-audio-title": "Editer un fichier audio",
    "edit-success": "Fichier audio mis à jour avec succès",
    "file": "Fichier",
    "header": "Fichiers audio",
    "label": "Bibliothèque audio",
    "language": "Langue",
    "legal-disclaimer": "En téléchargeant, en utilisant ou en affichant sur Cloud PURE de la musique, du contenu audiovisuel, des enregistrements sonores, des photographies, des œuvres d'art et d'autres formes de médias protégés par le droit d'auteur, vous garantissez à WTG que vous avez l'autorisation et/ou une licence d'utilisation de ce contenu. Le fait de le faire sans l'autorisation ou la licence du propriétaire constitue une violation de la loi sur les droits d'auteur et peut entraîner la suppression de ce contenu.",
    "or-choose": "ou",
    "save-success": "Fichier audio créé avec succès",
    "search": "Recherche de fichiers audio",
    "title": "Bibliothèque audio",
    "usage-type": {
      "ivr": "Menu IVR",
      "queue": "File d'attente du centre d'appel",
      "ring_group": "Groupe d'anneaux"
    },
    "used-in-title": "Utilisé en",
    "user": "Utilisateur",
    "visibility": {
      "personal": "Personnel",
      "public": "Publique"
    }
  },
  "members": "Appels entrants (file d'attente)",
  "merge": "Terminer le transfert",
  "min": "min",
  "minuteabbrev": "min",
  "missedcall": "Manqué",
  "missedcalls": "Appels manqués",
  "MobileTelephoneNumber": "Téléphone mobile",
  "mobiletitel": "Téléphone",
  "more": "Plus",
  "mute": "Mettre en sourdine",
  "my_favorites": "Mes favoris",
  "myActiveQueues": "Mes files d’attente actives",
  "name": "Nom",
  "newpassword": "Nouveau mot de passe",
  "newuser": "Nouvel utilisateur",
  "no": "Non",
  "no-data": "Aucune donnée disponible",
  "no_agent": "Aucun agent ne vous est attribué",
  "no-answer": "Aucune réponse",
  "no-number-available": "Aucun numéro disponible",
  "no-one-waiting": "Aucune attente.",
  "noActiveQueues": "Aucune file d’attente active",
  "nomissedcalls": "Vous n’avez rien manqué.",
  "not-available": "Aucun numéro disponible",
  "not-registered": "Non enregistré",
  "not-working": "Malheureusement, cela n’a pas fonctionné.",
  "notes": "Notes",
  "notice": "Notes",
  "notifications": {
    "banner": "Bannière",
    "banner_info": "Afficher une bannière de notification lors de la réception d’un appel dans le groupe de reprise",
    "notifications": "Communications",
    "pickup": "Reprise",
    "sound_status": "Son",
    "sound_status_info": "Lire le son à la réception d’un appel dans le groupe de reprise"
  },
  "notLoggedOut": "Toutes les personnes connectées",
  "number": "Numéro",
  "numpad": "Clavier de sélection",
  "offline_message": "Vous êtes déconnecté",
  "onbreak": "En pause",
  "organization": "Entreprise",
  "OtherFaxNumber": "Autre fax",
  "OtherTelephoneNumber": "Autres",
  "outlookContact": "Importé à partir d'Outlook",
  "Overview": "Aperçu",
  "park": "Mettre en attente",
  "park_call": "Mettre l’appel en attente",
  "park-call": "Mettre l’appel en attente",
  "parked-calls": "Appels en attente",
  "parking": "Parking",
  "participant": "Participant(s)",
  "participants": "Participant(s)",
  "password": "Mot de passe",
  "passwordhastocontain8chars": "Le mot de passe doit comporter au moins 8 caractères.",
  "passwordreset-success-msg": "Un e-mail a été envoyé à l’adresse indiquée afin de réinitialiser le mot de passe. Veuillez vérifier votre boîte de réception.",
  "passwordsdontmatch": "Les mots de passe ne correspondent pas.",
  "personalContact": "Contact personnel",
  "personalContacts": "Contacts personnels",
  "phone": "Téléphone",
  "phone-number": "Déviation d’appel",
  "phonebook-form": {
    "add": "Ajouter un groupe de l'annuaire",
    "delete-content": "Voulez-vous supprimer ce groupe de l'annuaire ?",
    "delete-error": "Impossible de supprimer le groupe de l'annuaire",
    "delete-success": "Le groupe de l'annuaire a été supprimé avec succès",
    "delete-title": "Suppression du groupe de l'annuaire",
    "edit": "Modifier un groupe de l'annuaire",
    "name": "Nom du groupe",
    "put-error": "Impossible de mettre à jour le groupe de l'annuaire",
    "put-success": "Le groupe de l'annuaire a été mis à jour avec succès",
    "save-error": "Impossible d'enregistrer le groupe de l'annuaire",
    "save-success": "Le groupe de l'annuaire a été enregistré avec succès",
    "search-contact": "Rechercher un contact"
  },
  "phonebook-groups": {
    "contacts-amount": "Nombre de contacts",
    "global-empty": "Aucun groupe central ajouté pour le moment !",
    "global-title": "Groupes centraux",
    "personal-empty": "Aucun groupe personnel ajouté pour le moment ! Cliquez sur Ajouter pour en créer un.",
    "personal-title": "Groupes personnels"
  },
  "phonebook-settings": {
    "CentralContacts": "Contacts centraux",
    "CentralGroups": "Groupes centraux",
    "label": "Annuaire"
  },
  "phonelabel": {
    "fax": "Fax",
    "mobile": "Mobile",
    "other": "Autres",
    "work": "Travail"
  },
  "pickup": "Reprise",
  "pickupGroups": {
    "addPickupGroup": "Ajouter un groupe de Pickup",
    "deleteGroupConfirmationMessage": "Voulez-vous vraiment supprimer le groupe de ramassage \"{name}\" ?",
    "deleteGroupConfirmationTitle": "Groupes de Pickup",
    "editPickupGroup": "Modifier le groupe de Pickup",
    "members": "Membres",
    "members-number": "Nombre de membres du groupe",
    "nameTaken": "Le nom est déjà utilisé !",
    "noMembers": "La liste des membres ne doit pas être vide !",
    "notBlank": "L'entrée ne doit pas être vide !",
    "numberOfMembers": "Nombre de membres",
    "pickupGroupsHeader": "Groupes de Pickup",
    "search": "Recherche de groupes de ramassage"
  },
  "position": "Position",
  "post-processing-time": "Temps de post-traitement",
  "previousversion": "Version précédente",
  "PrimaryTelephoneNumber": "Téléphone principal",
  "profile-data": "Données du profil",
  "pureContact": "Contact PURE",
  "pureContacts": "Contacts PURE",
  "queue": "File d’attente",
  "queues": "Files d’attente",
  "queues-waiting": "En attente",
  "RadioTelephoneNumber": "Appel radio",
  "recordings": "Enregistrements",
  "referred-by": "Saisi par",
  "reject": "Rejeter",
  "repeatpassword": "Répéter le mot de passe",
  "repeatuserpassword": "Répéter le mot de passe",
  "replayed": "Répondu",
  "request_supervisor": "Demander un superviseur",
  "request-password-reset-header": "Mot de passe oublié ?",
  "requests_supervisor": "Demande d’un superviseur",
  "reset-password": "Réinitialiser le mot de passe",
  "resetpassword": "Réinitialiser le mot de passe",
  "resetsuccessmessage": "Le mot de passe a été réinitialisé.",
  "resetsuccesstitle": "Mot de passe réinitialisé",
  "restriction-errors": {
    "at-least-one-target": "Au moins une option cible doit être ajoutée",
    "duplicate": "Cette valeur est déjà attribuée à l’utilisateur {user}",
    "empty": "Ne doit pas être vide",
    "exceeded-quota": "Quota dépassé pour l'utilisateur",
    "excluded-value-list-errors": {
      "CALL_CENTER_QUEUE": "Ce numéro est déjà utilisé pour une file d’attente du centre d’appels",
      "DESTINATION": "Ce numéro est déjà utilisé pour une destination",
      "EXTENSION": "Ce numéro est déjà utilisé par un autre utilisateur",
      "IVR_MENU": "Ce numéro est déjà utilisé pour un menu IVR",
      "other": "Ce numéro est déjà utilisé ailleurs",
      "RING_GROUP": "Ce numéro est déjà utilisé pour un groupe d’appels",
      "TIME_CONDITION": "Ce numéro est déjà utilisé pour une condition de temps"
    },
    "file-too-large": "Le fichier est trop volumineux. La taille maximale est de {sizeLimit} Mo",
    "invalid-file-type": "Type de fichier non valide",
    "passwords-not-matching": "Les mots de passe doivent correspondre",
    "regex-errors": {
      "DOMAIN_NUMBER_RESTRICTION": "Ce numéro de téléphone n’est pas autorisé pour votre compte",
      "EMAIL": "Adresse e-mail non valide",
      "EXTENSION_NUMBER": "Ne peut contenir que des chiffres et ne peut pas commencer avec un 0",
      "other": "Mauvais format",
      "PHONE_NUMBER": "Doit être un numéro de téléphone et commencer par +",
      "USERNAME": "Le nom d’utilisateur doit être composé de lettres, de chiffres, de « - », « _ » ou « . ».",
      "VOICEMAIL_PASSWORD": "Doit comporter 6 à 16 chiffres"
    },
    "too-short": "Doit contenir au moins {minimum} caractères",
    "unique-extension": "L'extension {extension} est déjà prise.",
    "unique-inbound-number": "Le numéro entrant {inboundNumber} est déjà pris.",
    "unique-username": "Le nom d'utilisateur {username} est déjà pris.",
    "unknown-error": "Erreur de validation",
    "wrong-format": "Mauvais format"
  },
  "return-to-login": "Retour à la connexion",
  "revert": "Supprimer les modifications",
  "ring-groups": {
    "accept": "Accepter",
    "active": "Actif",
    "add": "Ajouter",
    "addCallGroup": "Ajouter un groupe",
    "addDescription": "Ajouter une description",
    "addExtension": "Sélectionner une extension",
    "addName": "Ajouter un nom",
    "addTarget": "Ajouter une cible",
    "busyTone": "Tonalité d'occupation",
    "busyToneFieldDescription": "Définissez ce que l'appelant entend pendant que la cible est appelée.",
    "callerIdName": "Nom de l'appelant",
    "callerIdNameFieldDescription": "Définir le nom de l'ID de l'appelant pour les appels externes sortants.",
    "callerIdNumber": "Numéro de l'appelant",
    "callerIdNumberFieldDescription": "Définir le numéro d'identification de l'appelant pour les appels externes sortants.",
    "callGroupForward": "Transférer un groupe d'appel collectif",
    "callGroupForwardFieldDescription": "Déviation de l'ensemble du groupe d'appel collectif vers une destination sélectionnée.",
    "callGroupForwarding": "Transfert de groupes d'appels collectifs",
    "callGroupForwardingFieldDescription": "Transfert d'un groupe d'appel appelé vers une autre destination.",
    "callGroups": "Groupes d'appel collectif",
    "callTimeout": "Délai d'appel",
    "cancel": "Annuler",
    "chooseCallTimeout": "Choisir un délai d'appel",
    "delay": "Retarder",
    "deleteCallGroup": "Supprimer le groupe",
    "description": "Description",
    "editCallGroup": "Groupe Modifier",
    "emailAddress": "Adresse e-mail",
    "enterprise": "Entreprise",
    "extension": "Poste",
    "feesForwarding": "Transfert de frais d'appel",
    "followMe": "Suivez-moi",
    "followMeFieldDescription": "Choisissez l'option \"Suivez-moi\" pour une cible de groupe de sonnerie.",
    "forwarding": "Transfert",
    "forwardingFieldDescription": "Choisissez si le renvoi d'appel doit être appliqué au sein du groupe.",
    "greeting": "Salutation",
    "groupMembers": "Membres du groupe",
    "groups": "Groupes",
    "missedCalls": "Appels manqués",
    "missedCallsFieldDescription": "Choisissez si quelqu'un doit être averti par e-mail.",
    "name": "Nom",
    "random": "Aléatoire",
    "recall": "Rappel",
    "recallFieldDescription": "Spécifie ce que l'appelant entend pendant que la cible est appelée.",
    "rollover": "Renversement",
    "save": "Enregistrer",
    "search": "Recherche de groupes d'appel",
    "selectCallerIdName": "ID de l'appelant Choisir le nom",
    "selectCallerIdNumber": "Choisir une ID d'appelant",
    "selectContact": "Sélectionner un contact",
    "selectFeesForwarding": "Sélectionner transfert de frais d'appel",
    "selectGreeting": "Choisir une salutation",
    "selectNumber": "Sélectionner un numéro",
    "selectSound": "Choisir une sonnerie",
    "selectStrategy": "Choisir une stratégie",
    "selectTarget": "Sélectionner la cible",
    "sequence": "Séquence",
    "simultaneous": "Simultané",
    "strategy": "Stratégie",
    "target": "Cible",
    "targetByTimeLimit": "Cible par limite de temps",
    "targets": "Cibles",
    "targetsSectionDescription": "Ajoutez des objectifs et des paramètres au groupe d'anneaux.",
    "timeLimit": "Limite de temps",
    "typeOfNotification": "Type de notification"
  },
  "ringduration": "{seconds} s de sonnerie",
  "save": "Enregistrer",
  "savepassword": "Enregistrer le mot de passe",
  "search": "Recherche",
  "search-for-contact": "Rechercher contact",
  "search-for-user": "Recherche d’un utilisateur",
  "search-name": "Recherche",
  "sec": "sec.",
  "secondabbrev": "s",
  "secretary-function": {
    "active": "Actif",
    "target": "Mon secrétariat"
  },
  "secretary-function-settings": "Paramètres du secrétariat",
  "section_header_second": "Contacts",
  "select-break-reason": "Sélectionner le motif de la rupture",
  "serverunreachable": "Échec : Le serveur {0} n’est pas accessible.",
  "service-level": "Niveau de service",
  "set-agent-status": "Définir le statut de l'agent",
  "set-status": "Définir le statut",
  "settings": "Paramètres",
  "show_all": "Tout afficher",
  "signWithMicrosoft": "Connexion avec un compte Microsoft",
  "softclient-settings": {
    "label": "Paramètres du client logiciel",
    "MultiField": "Champs multiples",
    "Shortcuts": "Raccourcis clavier"
  },
  "sound-error": "Problèmes de microphone",
  "soundoutputsettings": "Réglages de sortie",
  "speaker": "Haut-parleur",
  "sso": {
    "error": {
      "ACCESS_TOKEN_NOT_AVAILABLE": "Jeton d'accès non disponible.",
      "GENERAL_ERROR": "Une erreur inattendue s'est produite lors de l'authentification SSO",
      "MULTIPLE_ACCOUNTS": "Plusieurs utilisateurs trouvés avec cette adresse e-mail. Veuillez contacter l'administrateur.",
      "SSO_DISABLED": "L'authentification unique est désactivée pour ce domaine.",
      "USER_NOT_FOUND": "Nous n’avons pas trouvé de compte associé à l’adresse e-mail fournie. Veuillez vérifier votre adresse e-mail ou contacter l’administrateur du système."
    }
  },
  "sso-settings": {
    "enabled-success": "L'authentification unique a été activée",
    "enable": "Activé",
    "disabled-success": "L'authentification unique a été désactivée",
    "label": "Authentification unique",
    "modification-error": "Une erreur s'est produite lors de la configuration de l'authentification unique. Veuillez réessayer",
    "SSOSettings": "Paramètres de l'authentification unique",
    "title": "Authentification unique"
  },
  "statistics": "Statistiques",
  "status": "État",
  "statusType": {
    "available": "Disponible",
    "away": "De retour dans un instant",
    "busy": "Occupé",
    "dnd": "Ne pas déranger",
    "hidden": "Afficher comme hors ligne"
  },
  "stayLoggedIn": "Rester connecté",
  "steady": "Durée",
  "supervisor": "Superviseur",
  "switchboard": "Central téléphonique",
  "take-a-break": "Faire une pause",
  "target": "Destination",
  "TelexNumber": "Télex",
  "time": "L'heure",
  "timeout": "Dépassement de temps",
  "title": "Mon statut",
  "to": "À",
  "today": "Aujourd’hui",
  "tologin": "Vers la connexion",
  "totallogintime": "Délai de connexion total",
  "TTYTDDTelephoneNumber": "Téléphone textuel",
  "type": "Type",
  "unhold": "Reprendre",
  "unknown": "Inconnu",
  "unknown-contact": "Contact inconnu",
  "unknownerror": "État d’erreur inconnu : {0}",
  "unknownversion": "Version inconnue",
  "unsavedchanges": {
    "cancel": "Adapter les modifications",
    "content": "Il y a des modifications non enregistrées. Souhaitez-vous les annuler avant de quitter ?",
    "invalid-content": "Il y a des modifications invalides non sauvegardées. Souhaitez-vous les annuler avant de partir ?",
    "invalidmessage": "Les modifications non enregistrées ne peuvent pas être enregistrées",
    "revert": "Supprimer les modifications",
    "save": "Enregistrer",
    "valid-content": "Certaines modifications n'ont pas été sauvegardées. Voulez-vous les sauvegarder avant de partir ?",
    "validmessage": "Modifications non enregistrées"
  },
  "user": {
    "changepassword": "Modifier le mot de passe",
    "deletePhotoConfirmationMessage": "Voulez-vous vraiment supprimer la photo ?",
    "deletePhotoConfirmationTitle": "Photo de profil",
    "deleteprofilepicture": "Supprimer la photo de profil",
    "disablePhone": "Désactiver la téléphonie dans le softclient",
    "extension": "Poste secondaire",
    "extensionpassword": "Mot de passe du poste secondaire",
    "language": "Langue",
    "languages": {
      "cs": "Tchèque",
      "de": "Allemand",
      "en": "Anglais",
      "fr": "Français"
    },
    "newpassword": "Nouveau mot de passe",
    "newprofilepicture": "Nouvelle photo de profil",
    "oldpassword": "Ancien mot de passe",
    "passwordchangeerror": "Cela n'a pas fonctionné. Veuillez vérifier votre ancien mot de passe et réessayer.",
    "passwordsdontmatch": "Les mots de passe ne correspondent pas",
    "passwordwaschanged": "Mot de passe modifié.",
    "profilepicture": "Photo de profil",
    "repeatnewpassword": "Répéter le mot de passe",
    "uploadprofilepicture": "Télécharger",
    "useatleast8characters": "Le mot de passe doit comporter au moins 8 caractères.",
    "userprofile": "Profil"
  },
  "user-at-dot": "Le nom d’utilisateur doit contenir une {'@'} et un .",
  "user-cant-be-edited": "L’utilisateur ne peut pas être modifié ici",
  "userhasnoextension": "Échec : L’utilisateur n’a pas attribué de poste secondaire.",
  "userLogin": "Utilisateur",
  "username": "Nom d’utilisateur",
  "userneedsdomain": "Échec : Le nom d’utilisateur doit contenir un domaine.",
  "userpassword": "Nouveau mot de passe",
  "Users": "Utilisateur",
  "validators": {
    "nonempty": "Ne peut être vide"
  },
  "video": "Vidéo",
  "view-details": "Voir les détails",
  "voicemail-active": "Messagerie vocale activée",
  "voicemail-language": "Langue de la messagerie vocale",
  "voicemail-password": "Mot de passe de la messagerie vocale",
  "voicemail-settings": "Messagerie vocale",
  "voicemails": "Messages vocaux",
  "wait-time": "Temps d’attente",
  "waiting": "Attente",
  "waiting-time": "Temps d’attente",
  "waitingMemberCount": "File d’attente",
  "waittime": "Temps d’attente",
  "warm": "Chaud",
  "warmtransfer": "Transférer",
  "will-be-set-on-save": "Défini au hasard lors de l’enregistrement",
  "work": "Travail",
  "wrap-up": "Récapitulation",
  "wrongcredentials": "Échec : L’utilisateur et le mot de passe ne correspondent pas.",
  "wtgcloudpure": "WTG Cloud PURE",
  "yes-delete": "Oui, supprimer",
  "yesterday": "Hier"
}