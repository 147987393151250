import { useSessionStore } from '@/stores/useSessionStore';
import { authClientApi, logoutUser, renewLogin, type UserCredentials } from '@/api/authClientApi';
import { createDomainAxiosInstance } from '@/api/axios';
import type { SessionLength } from '@/constants/browserStorage';

export const useAuthService = () => {
  const $sessionStore = useSessionStore();

  const login = async (userCredentials: UserCredentials, sessionLength: SessionLength) => {
    const session = await authClientApi(userCredentials, sessionLength);
    createDomainAxiosInstance(session.domain);
    $sessionStore.createSession({ ...session, sessionLength: sessionLength });
  };

  const logout = async () => {
    await logoutUser();
    await $sessionStore.logoutSession();
  };

  const refreshToken = async () => {
    if ($sessionStore._session.isAuthenticated === false) return Promise.reject('Not authenticated');
    const { domain, sessionLength } = $sessionStore._session;
    try {
      const newSessionData = await renewLogin(domain, sessionLength);
      createDomainAxiosInstance(newSessionData.domain);
      $sessionStore.createSession(newSessionData);
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw error;
    }
  };

  return {
    login,
    logout,
    refreshToken,
  };
};
