import { type RouteRecordRaw } from 'vue-router';
import type { UserPermission } from '@/stores/usePermissionsStore';

export const RouteName = {
  Login: 'Login',
  Home: 'Home',
  Phone: 'Phone',
  Toast: 'Toast',
  Call: 'Call',
  AdminUsers: 'Users',
  AdminUsersDetails: 'AdminUserDetails',
  ProfileSettings: 'ProfileSettings',
  CallForwardSettings: 'CallForwardSettings',
  PhonebookSettings: 'PhonebookSettings',
  FollowMeSettings: 'FollowMeSettings',
  ExtensionSettings: 'ExtensionSettings',
  VoicemailSettings: 'VoicemailSettings',
  AudioSettings: 'AudioSettings',
  NotificationSettings: 'NotificationSettings',
  ClientInformationSettings: 'ClientInformationSettings',
  CallScriptSettings: 'CallScriptSettings',
  ContactsSettings: 'ContactsSettings',
  HotkeysSettings: 'HotkeysSettings',
  LogsSettings: 'LogsSettings',
  Pickup: 'Pickup',
  PasswordResetRequest: 'RequestPasswordReset',
  PasswordReset: 'PasswordReset',
};

const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/toast',
    name: RouteName.Toast,
    component: () => import('@/views/Toast.vue'),
    meta: { requiresAuth: false, electronOnly: true },
  },
  {
    path: '/reset_password/',
    name: RouteName.PasswordReset,
    component: () => import('@/views/PasswordReset.vue'),
  },
  {
    path: '/public',
    component: () => import('@/layouts/PublicLayout.vue'),
    children: [
      {
        path: 'login',
        name: RouteName.Login,
        component: () => import('@/views/LoginView.vue'),
      },
      {
        path: 'requestpasswordreset',
        name: RouteName.PasswordResetRequest,
        component: () => import('@/views/RequestPasswordReset.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/layouts/AuthenticatedLayout.vue'), // General authenticated layout
    meta: { requiresAuth: true },
    children: [
      {
        path: '/client',
        component: () => import('@/layouts/ClientLayout.vue'),
        children: [
          {
            path: 'home',
            name: RouteName.Home,
            component: () => import('@/views/HomeView.vue'),
          },
          {
            path: 'phone',
            name: RouteName.Phone,
            component: () => import('@/views/PhoneView.vue'),
          },
          {
            path: 'contacts',
            name: 'Contacts',
            component: () => import('@/views/ContactsView.vue'),
          },
          {
            path: 'call/:callid',
            name: RouteName.Call,
            component: () => import('@/views/Call.vue'),
          },
          {
            path: 'queues',
            name: 'Queues',
            meta: { requiredPermissions: ['agent'] as UserPermission[] },
            component: () => import('@/views/CallCenterView.vue'),
          },
          {
            path: 'switchboard',
            name: 'SwitchBoard',
            meta: { requiredPermissions: ['switchboard'] as UserPermission[] },
            component: () => import('@/views/SwitchBoardView.vue'),
          },
        ],
      },
      {
        path: '/admin-portal/main',
        name: 'Overview',
        meta: { requiredPermissions: ['admin', 'superadmin', 'customer-admin'] as UserPermission[] },
        component: () => import('@/views/AdminPortal.vue'),
        children: [
          {
            path: 'phb',
            name: RouteName.PhonebookSettings,
            redirect: 'admin-portal/main',
            component: () => import('@/components/admin-portal/phonebook-settings/PhonebookSettings.vue'),
            children: [
              {
                path: 'cc',
                name: 'CentralContacts',
                component: () => import('@/views/CentralContactsView.vue'),
              },
              {
                path: 'cg',
                name: 'CentralGroups',
                component: () => import('@/views/CentralGroupsView.vue'),
              },
            ],
          },
          {
            path: 'cs',
            name: 'CallSettings',
            redirect: 'admin-portal/main',
            component: () => import('@/components/admin-portal/call-settings/CallSettings.vue'),
            children: [
              {
                path: 'ccg',
                name: 'RingGroups',
                component: () => import('@/views/CallGroupsView.vue'),
              },
              {
                path: 'ivr',
                name: 'IVR',
                component: () => import('@/views/IVRView.vue'),
              },
              {
                path: 'pu',
                name: 'Pickup',
                component: () => import('@/views/PickupView.vue'),
              },
            ],
          },
          {
            path: 'cc',
            name: 'CallCenter',
            redirect: 'admin-portal/main',
            component: () => import('@/components/admin-portal/call-center-queues/CallCenterSettings.vue'),
            children: [
              {
                path: 'q',
                name: 'QueuesSettings',
                component: () => import('@/views/CallCenterQueuesView.vue'),
              },
            ],
          },
          {
            path: 'in',
            name: 'Integration',
            redirect: 'admin-portal/main',
            component: () => import('@/components/admin-portal/integration/IntegrationSettings.vue'),
            children: [
              {
                path: 'as',
                name: 'AzureSettings',
                component: () => import('@/views/AzureIntegrationView.vue'),
              },
            ],
          },
          {
            path: 'ml',
            name: 'MediaLibrary',
            redirect: 'admin-portal/main',
            component: () => import('@/components/admin-portal/media-library/MediaLibrarySettings.vue'),
            children: [
              {
                path: 'ad',
                name: 'AudioLibrarySettings',
                component: () => import('@/views/AudioLibraryView.vue'),
              },
            ],
          },
          {
            path: 'sso',
            name: 'SSO',
            redirect: 'admin-portal/main',
            component: () => import('@/components/admin-portal/sso/SSOSettings.vue'),
            children: [
              {
                path: 'sso',
                name: 'SSOSettings',
                component: () => import('@/views/SSOSettingsView.vue'),
              },
            ],
          },
        ],
      },
      {
        path: '/admin-portal/users',
        name: RouteName.AdminUsers,
        component: () => import('@/views/UserAdministrationView.vue'),
        meta: { requiredPermissions: ['admin', 'superadmin', 'customer-admin'] as UserPermission[] },
        children: [
          {
            name: RouteName.AdminUsersDetails,
            path: ':uuid',
            component: () => import('@/components/admin-portal/users/admin-user-detail-form/AdminUserDetailForm.vue'),
          },
          {
            path: 'import',
            name: 'ImportUsers',
            component: {},
          },
        ],
      },
      {
        path: '/settings',
        name: 'Settings',
        component: () => import('@/views/Setting.vue'),
        children: [
          {
            path: 'profile',
            name: RouteName.ProfileSettings,
            component: () => import('@/views/settings/ProfileSettingsView.vue'),
          },
          {
            path: 'call-forward',
            name: RouteName.CallForwardSettings,
            component: () => import('@/views/settings/CallForwardSettingsView.vue'),
          },
          {
            path: 'follow-me',
            name: RouteName.FollowMeSettings,
            component: () => import('@/views/settings/FollowMeSettingsView.vue'),
          },
          {
            path: 'extension',
            name: RouteName.ExtensionSettings,
            component: () => import('@/views/settings/ExtensionSettingsView.vue'),
          },
          {
            path: 'voicemail',
            name: RouteName.VoicemailSettings,
            component: () => import('@/views/settings/VoicemailSettingsView.vue'),
          },
          {
            path: 'audio',
            name: RouteName.AudioSettings,
            component: () => import('@/views/settings/AudioSettingsView.vue'),
          },
          {
            path: 'notification',
            name: RouteName.NotificationSettings,
            component: () => import('@/views/settings/NotificationSettingsView.vue'),
          },
          {
            path: 'client-information',
            name: RouteName.ClientInformationSettings,
            component: () => import('@/views/settings/ClientInformationView.vue'),
          },
          {
            path: 'call-scripts',
            name: RouteName.CallScriptSettings,
            component: () => import('@/views/settings/CallScriptView.vue'),
          },
          {
            path: 'contacts',
            name: RouteName.ContactsSettings,
            component: () => import('@/views/settings/ContactsSettingsView.vue'),
          },
          {
            path: 'hotkeys',
            name: RouteName.HotkeysSettings,
            component: () => import('@/views/settings/HotkeysView.vue'),
          },
          {
            path: 'logs',
            name: RouteName.LogsSettings,
            component: () => import('@/views/settings/LogsView.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/client/home',
  },
];

export default routes;
